var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section GlobalSettings v2"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Global settings")])], 1)] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm._v(" Global settings "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'globalSettings'
        });
      }
    }
  })], 1)]], 2), _vm._m(0), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox GlobalSetingsSection-1"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px mb-32px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100 GlobalSetingsSection-1-1"
  }, [_c('div', {
    staticClass: "verySmallBlackBorderedWhiteBox"
  }, [_c('span', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "sm-mar-right",
    attrs: {
      "icon": "latest-clockinsideGlobalSettings-icon",
      "width": "19.292px",
      "height": "19.292px"
    }
  }), _vm._v(" " + _vm._s(_vm.current_date.time) + " ")], 1), _c('span', {
    staticClass: "d-flex align-items-center ml-60px"
  }, [_c('vb-icon', {
    staticClass: "sm-mar-right",
    attrs: {
      "icon": "latest-calenderInsideGlobalSettings-icon",
      "width": "18.006px",
      "height": "17.617px"
    }
  }), _vm._v(" " + _vm._s(_vm.current_date.date) + " ")], 1)])]), _c('div', {
    staticClass: "d-flex align-items-center juystify-content-between GlobalSetingsSection-1-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap w-100 mb-60px GlobalSetingsSection-1-2-1"
  }, [_c('form', {
    staticClass: "dialer-form mr-32px",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "profileImg-section-latest-container"
  }, [_c('div', {
    staticClass: "profileImg-section-latest",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('GlobalSettingProfileImageUploader', {
          type: 'COMPANY',
          accountcode: _vm.getCurrentUser.default_accountcode,
          current_image: _vm.getCurrentUser.companyLogo
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.getCurrentUser.companyLogo,
      "alt": 'Company Image'
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', [_c('h1', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(`${_vm.getCurrentUser.company}`))]), _c('h4', [_vm._v(_vm._s(` (${_vm.getCurrentUser.uid})`))])])])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 mt-20px GlobalSetingsSection-1-2-2"
  }, [_c('b-form-select', {
    attrs: {
      "disabled": _vm.api.fetch_account_detail.send || _vm.api.update_time_zone.send,
      "options": _vm.countries
    },
    on: {
      "change": function ($event) {
        _vm.forms.update_time_zone.time_zone = '';
      }
    },
    model: {
      value: _vm.forms.update_time_zone.country,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_time_zone, "country", $$v);
      },
      expression: "forms.update_time_zone.country"
    }
  }), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_time_zone.time_zone,
      expression: "forms.update_time_zone.time_zone"
    }],
    staticClass: "form-control custom-select mt-20px",
    attrs: {
      "disabled": _vm.api.fetch_account_detail.send || _vm.api.update_time_zone.send
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.forms.update_time_zone, "time_zone", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.updateTimeZone();
      }]
    }
  }, _vm._l(_vm.timezones, function (timeZone) {
    return _c('option', {
      key: timeZone
    }, [_vm._v(_vm._s(timeZone))]);
  }), 0)], 1)])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.blf
    },
    attrs: {
      "title": _vm.getUserPermissions.blf ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-form-checkbox', {
    ref: "blf_switch",
    staticClass: "newerSwitch ml-4",
    attrs: {
      "disabled": _vm.api.fetch_account_detail.send || _vm.api.update_blf.send || !_vm.getUserPermissions.blf,
      "name": "check-button",
      "switch": "",
      "checked": _vm.forms.update_blf.blf
    },
    on: {
      "change": function ($event) {
        return _vm.updateBLF();
      }
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(2), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.status
    },
    attrs: {
      "title": _vm.getUserPermissions.status ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.status ? '' : _vm.$modal.show('StatusesModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-status-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Status list")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(3), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.call_tagging_and_notes
    },
    attrs: {
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    attrs: {
      "aria-disabled": !_vm.getUserPermissions.call_tagging_and_notes
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.call_tagging_and_notes ? '' : _vm.$modal.show('TagsModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-tags-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Set call tags")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(4), _c('div', {
    staticClass: "w-fit-content"
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-PowerDialerTags`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-tags-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Set power dialer tags")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(5), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.call_pickup
    },
    attrs: {
      "title": _vm.getUserPermissions.call_pickup ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    attrs: {
      "aria-disabled": !_vm.getUserPermissions.call_pickup
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.call_pickup ? '' : _vm.$modal.show('PickupGroupsModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(6), _c('div', [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('NumberBlocking');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(7), _c('div', [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SoundLibrary');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(8), _c('div', [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallRecording');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(9), _c('div', [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SomethingVoicemailModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(10), _c('div', [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('PublicHolidayModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("View")])], 1)])])])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox vm--container scrollable",
    attrs: {
      "name": "SomethingVoicemailModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Voicemail greeting settings")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('SomethingVoicemailModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('UserRecordedVoicemail', {
    staticClass: "s2-mobile",
    attrs: {
      "accountcode": _vm.getCurrentUser.default_accountcode
    }
  })], 1)])]), _c('StatusesModal'), _c('TagsModal'), _c('PowerDialerTagsModal', {
    attrs: {
      "modalName": `${_vm._uid}-PowerDialerTags`
    }
  }), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox vm--container scrollable",
    attrs: {
      "name": "PublicHolidayModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pb-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Public Holiday Notification")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('PublicHolidayModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("These days are automatically blocked from having Meetings scheduled.")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showLastTDonHover mt-0",
    attrs: {
      "isListEmpty": false,
      "listLength": 10,
      "perPage": 5,
      "conditions": {
        loading: {
          show: true
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', [_c('td', {
          staticClass: "dialer-row-title"
        }, [_vm._v(" Independence Day ")]), _c('td', {
          staticClass: "dialer-has-sort dialer-col-center"
        }, [_vm._v(" 3 Jul 2023 ")]), _c('td', {
          staticClass: "dialer-has-sort dialer-col-center"
        }, [_c('div', {
          staticClass: "unailableTime"
        }, [_c('div', {
          staticClass: "unailableTime-heading"
        }, [_vm._v("Unvailable all day")]), _c('div', {
          staticClass: "unailableTime-text"
        }, [_vm._v("Call will be shared to voicemail")])])]), _c('td', {
          staticClass: "dialer-has-sort dialer-col-center"
        }, [_c('b-icon', {
          attrs: {
            "icon": `${true ? 'play-fill' : 'pause-fill'}`,
            "font-scale": "1.5"
          }
        })], 1), _c('td', {
          staticClass: "dialer-has-sort dialer-col-right w-fit-content"
        }, [_c('div', {
          staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter optionIsdotted filterWithText"
        }, [_c('b-dropdown', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "d-flex justify-content-end w-100"
              }, [_c('b-icon', {
                staticClass: "fillBlack sm-mar-left",
                attrs: {
                  "icon": "three-dots-vertical",
                  "variant": "dark",
                  "scale": "1.5"
                }
              })], 1)];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', [_vm._v("Edit")]), _c('b-dropdown-item', [_vm._v("Delete")])], 1)], 1)])])];
      },
      proxy: true
    }])
  }, [!_vm.$store.getters.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort px-2"
  }, [_c('span', [_vm._v("Holiday")])]), _c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-center"
  }, [_c('span', [_vm._v("Date")])]), _c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-center"
  }, [_c('span', [_vm._v("Unavailable time")])]), _c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-center"
  }, [_c('span', [_vm._v("Announcment")])]), _c('th', {
    staticClass: "dialer-has-sort px-2 dialer-col-right w-fit-content"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('a', {
    staticClass: "newButton blackBG",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddPublicHolidayModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add holiday")])], 1)])])]) : _vm._e()]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Telephone numbers")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("These days are automatically blocked from having Meetings scheduled.")])]), _c('div', [_c('a', {
    staticClass: "newButton blackBG",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('PublicHolidayModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add Number")])], 1)])]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "isListEmpty": false,
      "listLength": 10,
      "perPage": 5,
      "conditions": {
        loading: {
          show: true
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.testDATA, function (number) {
          return _c('tr', {
            key: number.id
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "d-flex justify-content-between dialer-numberWithCountry"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "country-img-container p-0"
          }, [number.object === 'TelephoneNumbers' ? _c('vb-icon', {
            attrs: {
              "icon": "numbersSettings-table-tdIcon",
              "width": "35px",
              "height": "35px"
            }
          }) : number.object === 'CallerId' ? _c('vb-icon', {
            attrs: {
              "icon": "numbersSettings-table-tdIcon-another",
              "width": "35px",
              "height": "35px"
            }
          }) : _c('vb-icon', {
            attrs: {
              "icon": "numbersSettings-table-tdIcon",
              "width": "35px",
              "height": "35px"
            }
          })], 1), number.queue_service ? _c('span', {
            staticClass: "ml-2 d-flex flex-column dialer-numberInfo justify-content-center"
          }, [_c('span', {
            staticClass: "m-0 didNumber"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.name)))])]) : number.object === 'TelephoneNumbers' ? _c('span', {
            staticClass: "ml-2 d-flex flex-column dialer-numberInfo justify-content-center"
          }, [_c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "m-0 didNumber",
            attrs: {
              "title": number.is_trial == 1 ? 'Default' : ''
            }
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))]), _c('div', {
            staticClass: "d-flex align-items-center"
          }, [number.label ? _c('small', {
            staticClass: "didNumber-text mr-2 w-auto h-fit-content addedLabelInsideEditNumbersModal"
          }, [_vm._v("(" + _vm._s(number.label) + ")")]) : _vm._e(), number.status == 2 ? _c('div', {
            staticClass: "suspendedTag"
          }, [_vm._v("Suspended")]) : _vm._e()])]) : _c('span', {
            staticClass: "d-flex flex-column ml-1 dialer-numberInfo justify-content-center"
          }, [_c('span', {
            staticClass: "m-0 didNumber"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))]), number.status == 2 ? _c('div', {
            staticClass: "suspendedTag"
          }, [_vm._v("Suspended")]) : _vm._e()])]), _c('span', {
            staticClass: "d-flex align-items-center"
          }, [number.queue_service ? _c('img', {
            staticClass: "country-img",
            staticStyle: {
              "border-radius": "8px"
            },
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(number.name))
            }
          }) : _c('img', {
            staticClass: "country-img",
            staticStyle: {
              "border-radius": "8px"
            },
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(number.number))
            }
          })])])]), _c('td', {
            staticClass: "dialer-row-title two"
          }, [number.object === 'TelephoneNumbers' ? _c('div', {
            staticClass: "w-fit-content"
          }, [number.ringgroupId == number.assigned ? [number.ringgroup_members[0] ? _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('Info', {
            attrs: {
              "id": number.ringgroup_members[0],
              "is_blf": false
            }
          })], 1)]) : _vm._e()] : number.assigned ? [_c('div', [_c('Info', {
            attrs: {
              "id": number.assigned,
              "is_blf": false
            }
          })], 1)] : _vm._e()], 2) : _vm._e()]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-right w-fit-content"
          }, [_c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter optionIsdotted filterWithText"
          }, [_c('b-dropdown', {
            attrs: {
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('div', {
                  staticClass: "d-flex justify-content-end w-100"
                }, [_c('b-icon', {
                  staticClass: "fillBlack sm-mar-left",
                  attrs: {
                    "icon": "three-dots-vertical",
                    "variant": "dark",
                    "scale": "1.5"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v("Edit")]), _c('b-dropdown-item', [_vm._v("Delete")])], 1)], 1)])]);
        });
      },
      proxy: true
    }])
  }, [!_vm.$store.getters.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort px-2 py-1"
  }), _c('th', {
    staticClass: "dialer-has-sort px-2 py-1"
  }), _c('th', {
    staticClass: "dialer-has-sort px-2 py-1 dialer-col-right w-fit-content"
  })]) : _vm._e()])], 1)]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 EditUserModal bigSquareCheckbox vm--container scrollable",
    attrs: {
      "name": "AddPublicHolidayModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pb-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add public holiday")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddPublicHolidayModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("This days are automatically blocked from having Meetings scheduled.")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Holiday name")]), _c('input', {
    staticClass: "w-100 bg-white",
    attrs: {
      "type": "text"
    }
  })]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-100 mt-20px"
  }, [_c('label', [_vm._v("From:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "only-date": ""
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1), _c('div', {
    staticClass: "latestGreyBox-heading-main-16px mt-20px"
  }, [_vm._v("What happens to calls..")]), _c('div', {
    staticClass: "newBusinessHours mt-8px"
  }, [_c('div', {
    staticClass: "newBusinessHours-row"
  }, [_c('div', {
    staticClass: "newBusinessHours-row-left"
  }, [_c('div', {
    staticClass: "newBusinessHours-row-headingContainer"
  }, [_c('div', {
    staticClass: "newBusinessHours-row-heading"
  }, [_vm._v("Monday")]), _c('vb-icon', {
    attrs: {
      "icon": "businessHours-addSchedule-icon",
      "width": "20.76px",
      "height": "20.76px"
    }
  })], 1)]), _c('div', {
    staticClass: "newBusinessHours-row-right"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "newBusinessHours-row-right-row"
    }, [_c('div', {
      staticClass: "newBusinessHours-row-right-row-left"
    }, [_c('div', {
      staticClass: "newBusinessHours-row-right-row-left-button"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "businessHours-editSchedulePencil-icon",
        "width": "8.762px",
        "height": "8.761px"
      }
    }), _c('span', [_vm._v("Edit")])], 1)]), _c('div', {
      staticClass: "newBusinessHours-row-right-row-right"
    }, [_c('div', {
      class: [`red newBusinessHours-row-right-row-right-heading`]
    }, [_vm._v(" 00:00:00 - 00:00:00 ")]), [_c('div', {
      staticClass: "newBusinessHours-row-right-row-right-text"
    }, [_vm._v("Unavailable all day")])]], 2)]);
  }), 0)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Holiday Announcement")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("This sound file will play when a call will come on this holiday.")])]), _c('a', {
    staticClass: "newButton sm-mar-left"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add")])], 1)]), _c('div', {
    staticClass: "w-50 mt-12px"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
    attrs: {
      "design": `${_vm.$store.getters.getIsMobile ? 'minimum' : ''}`
    }
  })], 1)])])])])], 1), _c('ProfileImageUploader', {
    attrs: {
      "modalName": `GlobalSettingProfileImageUploader`
    },
    on: {
      "image-uploaded": function ($event) {
        '';
      }
    }
  }), _c('PickupGroupsModal'), _c('NumberBlockingModal'), _c('CallRecordingModal'), _c('SoundLibraryModal'), _c('AllExtensionsModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v("Global configurations affecting users and devices across the organization")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Busy lamp field")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Use the BLF button to effectively track a user's live status, allowing you to instantly determine their availability or current call status.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Indicate your availability or current status, such as available, away, or busy.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call tags")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Call tagging helps organise and analyse call data, enabling easy reporting and customer identification.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Power Dialer tags")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Call tagging helps organise and analyse call data, enabling easy reporting and customer identification.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Pickup groups")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Users within a group can answer calls for each other, ensuring efficient call handling.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Number blocking")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Block unwanted telephone numbers by adding them to a block list, preventing calls to or from those numbers.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Sound library")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("A library of all available sound files for announcements and music on hold, including both our collection and customer uploads.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call recording")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Record and save audio from phone conversations.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Voicemail ")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Set a voicemail message to be used for all users who haven't personalised their own.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Public Holiday ")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("These days are automatically blocked from having Meetings scheduled.")])]);

}]

export { render, staticRenderFns }