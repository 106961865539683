<template>
  <div>
    <div v-if="twoPartsView" :class="`aiTranscriptModal-twoParts-container AudioTranscriptContainer ${transcriptStatus==3 ? '' : 'addScrollToIt'}`">
      <template v-if="transcriptStatus==3">
        <div class="aiTranscriptModal-twoParts-leftSide">
          <Item1 v-if="CallItemShow" class="m-0 w-100" :call="call"></Item1>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="latestGreyBox-heading-main-16px w-100 d-flex align-items-center">
              <span class="mr-12px">AI call summary</span>
              <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
            </div>
            <div class="latestGreyBox-descriptionText-13px mt-10px">{{transcriptSummary}}</div>
            <div class="latestGreyBox-heading-main-16px mt-20px w-100 d-flex align-items-center">
              <span class="mr-12px">AI keywords</span>
              <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
            </div>
            <div class="AI-Keywords-container">
              <div class="AI-Keyword" v-for="data in transcriptTags" :key="data.id">{{data}}</div>
            </div>
            <div class="latestGreyBox-heading-main-16px w-100 d-flex align-items-center mt-10px">
              <span class="mr-12px">Sentiments</span>
              <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
            </div>
            <div class="Sentiments-boxes">
              <div class="Sentiments-box" v-for="item in total_percentages" :key="item.id">
                <div class="Sentiments-box-heading">{{item.type}}</div>
                <div class="newProgressBar">
                  <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${(item.sum/transcriptTotalCount) * 100};--fg:#249CE0`">
                    <div class="newProgressBar-inner">
                      {{(item.sum/transcriptTotalCount) * 100 | to_fixed(0)}}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <slot name="transcript-end" />
        </div>
        <div class="aiTranscriptModal-twoParts-rightSide">
          <div class="latestGreyBox-9-9-2023 withoutBorderRadius">
            <div class="latestGreyBox-heading-main w-100 d-flex align-items-center">
              <span class="mr-12px">AI transcript</span>
              <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
            </div>
            <div class="latestGreyBox-descriptionText-subText-14px mt-12px">{{totalDuration/60  | to_fixed}} mint read</div>
            <div class="TranscriptText-container" ref="onlyScrollThis">
              <div class="TranscriptText-itself speaker" v-for="(data, index) in transcript" :key="index">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="TranscriptText-heading">{{ data.channel_type }}</div>
                  <div class="latestGreyBox-descriptionText-13px">{{ (data.end - data.start) | to_fixed }} s</div>
                </div>
                <p 
                  class="TranscriptText-text" 
                  :class="`${currentTime > parseFloat(data.start) && currentTime < parseFloat(data.end) ? 'highlight':''} ${data.channel_type}`"
                >
                  <span 
                    v-for="(word, wordIndex) in data.text.split(' ')" 
                    :key="wordIndex"
                    :class="{
                      'highlight-word': currentTime >= (data.start + (wordIndex * (data.end - data.start) / (data.text.split(' ').length + 2))),
                    }"
                  >
                    {{ word }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="aiTranscriptModal-twoParts-rightSide-borderSection">
            <vb-audio-player :src="soundFile" @currentTime="currentTime=$event;scrollView()" :design="'Transcript'">
              <template #player-end>
                <div class="TranscriptAudio-downloadShareSection">
                  <vb-icon class="cursor_pointer_no_hover mr-32px" icon="audioTranscript-share-icon" width="17.119px" height="18.826px" />
                  <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="16.2px" height="16.197px" @click="download()" />
                </div>
              </template>
            </vb-audio-player>
            <div class="AudioTranscript-SpeakerPart mt-20px">
              <div v-for="(timeline,speaker) in speakerTimeline" :key="speaker" class="SpeakerPart-item">
                <div class="speaker-heading">{{ speaker }}</div>
                <div class="d-flex timeline-container w-100 mt-10px">
                  <div
                    v-for="(data,index) in timeline" 
                    :key="index" 
                    :style="`
                      height: 10px;
                      width: ${data.per}%;
                    `"
                    :class="`${data.state==1 ? 'timeline' : ''} ${speaker}`"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else-if="api.fetch_transcript.send" class="d-flex justify-content-center w-100">
        <div class="mt-20px mb-20px w-50">
          <div v-for="n in 4" :key="n.id" :class="`latestShimmerDesign w-100 my-4`" style="height:80px;"></div>
        </div>
      </div>
      <div v-else-if="transcriptStatus==1" class="d-flex justify-content-center w-100">
        <div class="mt-20px mb-20px w-50">
          <vb-audio-player class="w-100" :src="soundFile" :design="'NoTranscript'" >
            <template #player-end>
              <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="14.2px" height="14.2px" @click="download()" />
            </template>
          </vb-audio-player>
          <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
            <img width="50%" height="auto" :src="require('@/assets/images/emptyScreenImages/audioTranscript.png')"/>
            <div class="emptyScreenContainer-heading mt-20px">Unlock intelligent insights! 🎙️🔍</div>
            <div class="emptyScreenContainer-text w-75">
              Transcribe your call recordings effortlessly with our AI-powered transcription feature. 
              Tap the button below, and watch as your audio transforms into actionable text. Elevate 
              your communication experience now!
            </div>
            <div v-b-tooltip :title="getUserPermissions.call_recording_transcriptions?'':'Your package does not support this feature'">
              <button :disabled="!getUserPermissions.call_recording_transcriptions" class="dialer-button dialer-button-primary mt-20px" @click="makeTranscript()">Transcript recording with AI</button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="transcriptStatus==2" class="d-flex justify-content-center w-100">
        <div class="mt-20px mb-20px">
          <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
            <img width="20%" height="auto" :src="require('@/assets/images/emptyScreenImages/transcriptionProcessing.png')"/>
            <div class="emptyScreenContainer-heading mt-20px">We are processing your transcript</div>
            <div class="emptyScreenContainer-text w-75">
              We are currently processing your transcript to convert the recorded audio into text. This ensures accurate and detailed documentation of your conversation, which will be available shortly.
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="transcriptStatus==0" class="d-flex justify-content-center w-100">
        <div class="mt-20px mb-20px w-50">
          <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
            <img width="50%" height="auto" :src="require('@/assets/images/emptyScreenImages/audioTranscript.png')"/>
            <div class="emptyScreenContainer-heading mt-20px">AI transcription is off! 🎙️🔍</div>
            <div class="emptyScreenContainer-text w-75">
              If you want you want your call recordings to be AI transcribed, then turn this service on from number settings.
            </div>
          </div>
        </div>
      </div>
      <div class="w-100" v-else-if="api.fetch_transcript.status==2">
        <div class="mt-20px mb-20px d-flex justify-content-center align-items-center w-100">
          <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
            <img width="20%" height="auto" :src="require('@/assets/images/emptyScreenImages/transcriptionError.png')"/>
            <div class="emptyScreenContainer-heading mt-20px">Error in Transcription</div>
            <div class="emptyScreenContainer-text w-75">
              We encountered an issue while processing your audio file. Please try again, or contact support if the problem persists.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="AudioTranscriptContainer">
      <!-- complete -->
      <template v-if="transcriptStatus==3">
        <div class="latestGreyBox-9-9-2023">
          <div class="latestGreyBox-heading-main-16px w-100 d-flex align-items-center">
            <span class="mr-12px">AI call summary</span>
            <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
          </div>
          <div class="latestGreyBox-descriptionText-13px mt-10px">{{transcriptSummary}}</div>
          <div class="latestGreyBox-heading-main-16px mt-20px w-100 d-flex align-items-center">
            <span class="mr-12px">AI keywords</span>
            <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
          </div>
          <div class="AI-Keywords-container">
            <div class="AI-Keyword" v-for="data in transcriptTags" :key="data.id">{{data}}</div>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="latestGreyBox-heading-main w-100 d-flex align-items-center">
            <span class="mr-12px">AI transcript</span>
            <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
          </div>
          <div class="latestGreyBox-descriptionText-subText-14px mt-12px">{{totalDuration/60  | to_fixed}} mint read</div>
          <div class="TranscriptText-container" ref="onlyScrollThis">
            <div class="TranscriptText-itself speaker" v-for="(data, index) in transcript" :key="index">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="TranscriptText-heading">{{ data.channel_type }}</div>
                <div class="latestGreyBox-descriptionText-13px">{{ (data.end - data.start) | to_fixed }} s</div>
              </div>
              <p 
                class="TranscriptText-text" 
                :class="`${currentTime > parseFloat(data.start) && currentTime < parseFloat(data.end) ? 'highlight':''} ${data.channel_type}`"
              >
                <span 
                  v-for="(word, wordIndex) in data.text.split(' ')" 
                  :key="wordIndex"
                  :class="{
                    'highlight-word': currentTime >= (data.start + (wordIndex * (data.end - data.start) / (data.text.split(' ').length + 2))),
                  }"
                >
                  {{ word }}
                </span>
              </p>
            </div>
          </div>
          <vb-audio-player :src="soundFile" @currentTime="currentTime=$event;scrollView()" :design="'Transcript'">
            <template #player-end>
              <div class="TranscriptAudio-downloadShareSection">
                <vb-icon class="cursor_pointer_no_hover mr-32px" icon="audioTranscript-share-icon" width="17.119px" height="18.826px" />
                <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="16.2px" height="16.197px" @click="download()" />
              </div>
            </template>
          </vb-audio-player>
          <div class="AudioTranscript-SpeakerPart mt-20px">
            <div v-for="speaker in speakers" :key="speaker" class="SpeakerPart-item">
              <div class="speaker-heading">
                {{ speaker }}
              </div>
              <div class="d-flex timeline-container w-100 mt-10px">
                <div
                  v-for="(data,index) in transcript" 
                  :key="index" 
                  :something="((data.end - data.start)/ transcriptTotalCount) * 100"
                  :style="`
                    height: 10px;
                    width: ${((data.end - data.start) / transcriptTotalCount) * 100}%;
                  `"
                  :class="`${speaker==data.channel_type ? 'timeline' : ''} ${speaker}`"
                  @click="speaker==data.channel_type?'':''"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- <div>
          <div>
            <pre class="mt-2 p-2 border" style="text-wrap: auto;color: currentColor;">{{ response.ask_question.text }}</pre>
          </div>
          <b-form @submit="askQuestion()">
            <b-row>
              <b-col cols="10">
                <b-form-group>
                  <b-form-input
                    placeholder="Ask Question"
                    v-model="forms.ask_question.question" 
                    :disabled="api.ask_question.send"
                    :state="$v.forms.ask_question.question.$error ? false : null" 
                  />
                  <b-form-invalid-feedback>
                    <template v-if="!$v.forms.ask_question.question.required">Question is required</template>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button type="submit" :disabled="api.ask_question.send" variant="primary" class="w-100">
                  <b-icon v-if="api.ask_question.send" icon="circle-fill" animation="throb" />
                  <b-icon icon="chevron-compact-right" />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div> -->
        <slot name="transcript-end" />
      </template>
      <!-- loading -->
      <div v-else-if="api.fetch_transcript.send" class="mt-20px mb-20px">
        <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
          <img width="50%" height="auto" :src="require('@/assets/images/emptyScreenImages/transcriptionProcessing.png')"/>
          <div class="emptyScreenContainer-heading mt-20px">We are processing your transcript</div>
          <div class="emptyScreenContainer-text w-75">
            We are currently processing your transcript to convert the recorded audio into text. This ensures accurate and detailed documentation of your conversation, which will be available shortly.
          </div>
        </div>
      </div>
      <!-- ready to make a transcript -->
      <div v-else-if="transcriptStatus==1">
        <div class="mt-20px mb-20px">
          <vb-audio-player :src="soundFile" :design="'NoTranscript'" >
            <template #player-end>
              <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="14.2px" height="14.2px" @click="download()" />
            </template>
          </vb-audio-player>
          <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
            <img width="50%" height="auto" :src="require('@/assets/images/emptyScreenImages/audioTranscript.png')"/>
            <div class="emptyScreenContainer-heading mt-20px">Unlock intelligent insights! 🎙️🔍</div>
            <div class="emptyScreenContainer-text w-75">
              Transcribe your call recordings effortlessly with our AI-powered transcription feature. 
              Tap the button below, and watch as your audio transforms into actionable text. Elevate 
              your communication experience now!
            </div>
            <div v-b-tooltip :title="getUserPermissions.call_recording_transcriptions?'':'Your package does not support this feature'">
              <button :disabled="!getUserPermissions.call_recording_transcriptions" class="dialer-button dialer-button-primary mt-20px" @click="makeTranscript()">
                Transcript recording with AI
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- processing -->
      <div v-else-if="transcriptStatus==2">
        <div class="d-flex justify-content-center w-100">
          <div class="mt-20px mb-20px">
            <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
              <img width="20%" height="auto" :src="require('@/assets/images/emptyScreenImages/transcriptionProcessing.png')"/>
              <div class="emptyScreenContainer-heading mt-20px">We are processing your transcript</div>
              <div class="emptyScreenContainer-text w-75">
                We are currently processing your transcript to convert the recorded audio into text. This ensures accurate and detailed documentation of your conversation, which will be available shortly.
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- not allowed -->
      <div v-else-if="transcriptStatus==0">
        <div class="d-flex justify-content-center w-100">
          <div class="mt-20px mb-20px w-50 emptyScreenContainer-container">
            <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
              <img width="50%" height="auto" :src="require('@/assets/images/emptyScreenImages/audioTranscript.png')"/>
              <div class="emptyScreenContainer-heading mt-20px">AI transcription is off! 🎙️🔍</div>
              <div class="emptyScreenContainer-text w-75">If you want you want your call recordings to be AI transcribed, then turn this service on from number settings.</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="api.fetch_transcript.status==2">
        <div class="mt-20px mb-20px">
          <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
            <img width="20%" height="auto" :src="require('@/assets/images/emptyScreenImages/transcriptionError.png')"/>
            <div class="emptyScreenContainer-heading mt-20px">Error in Transcription</div>
            <div class="emptyScreenContainer-text w-75">
              We encountered an issue while processing your audio file. Please try again, or contact support if the problem persists.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import Item1 from '../Home/CallActivity/Item1.vue';
// import required from 'vuelidate/lib/validators/required';
// import { AMI_SOCKET } from '@/ami';
export default {
  name: 'AudioTranscriptDummy',
  components: {
    Item1
  },
  inject: [
    'appNotify',
  ],
  props: {
    soundFile: {
      type: String,
      default: '',
    },
    uniqueID: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'call',
    },
    call: {
      type: Object,
      default: () => ({}),
    },
    CallItemShow: {
      type: Boolean,
      default: false,
    },
    twoPartsView: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentTime: 0,
      forms: {
        // ask_question: this.$helperFunction.formInstance({
        //   data: {
        //     question: '',
        //   },
        // }),
      },
      api: {
        fetch_transcript: this.$helperFunction.apiInstance({ status: true }),
        make_transcript: this.$helperFunction.apiInstance(),
        download: this.$helperFunction.apiInstance(),
        // ask_question: this.$helperFunction.apiInstance({ status: true }),
        // make_summary: this.$helperFunction.apiInstance({ status: true }),
        // make_keywords: this.$helperFunction.apiInstance({ status: true }),
        // cancel_ask_question: this.$helperFunction.apiInstance(),
        // cancel_make_summary: this.$helperFunction.apiInstance(),
        // cancel_make_keywords: this.$helperFunction.apiInstance(),
      },
      response: {
        transcript: {},
        // ask_question: {
        //   task_id: '',
        //   server_id: '',
        //   text: '',
        // },
        // make_summary: {
        //   task_id: '',
        //   server_id: '',
        //   text: '',
        // },
        // make_keywords: {
        //   task_id: '',
        //   server_id: '',
        //   text: '',
        // },
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
    ]),
    transcriptStatus(){ return this.response.transcript?.status },
    JSONText(){ 
      const json_text = this.response.transcript?.json_text
      if(json_text) return JSON.parse(json_text)
      return null 
    },
    transcript(){ return this.JSONText?.transcription ?? [] },
    speakers(){ return Object.keys(_.keyBy(this.transcript, 'channel_type') ?? {}) },
    totalDuration(){ return this.transcript[this.transcript.length-1]?.end },
    speakerTimeline(){
      const speakers = _.groupBy(this.transcript,'channel_type')
      const stepsArray = Array.from({ length: Math.floor(Math.ceil(this.totalDuration) / 0.25) + 1 },(_, i) => (i * 0.25).toFixed(2))
      let speakers_timeline = {}
      Object.keys(speakers).forEach((speaker)=>{
        const array = stepsArray.map(i=>speakers[speaker].findIndex(e=>i > e.start && i < e.end)>-1 ? 1 : 0)
        let result = [];
        let currentState = array[0];
        let count = 0;
        const total = array.length;
        for (let i = 0; i <= total; i++) {
          if (array[i] === currentState) {
            count++;
          } else {
            let percentage = (count / total) * 100;
            result.push({ state: currentState, per: percentage.toFixed(2) });
            currentState = array[i];
            count = 1;
          }
        }
        speakers_timeline[speaker]=result
      })
      return speakers_timeline
    },
    transcriptSummary(){ return this.JSONText?.summary },
    transcriptTags(){ return this.JSONText?.tags ?? [] },
    transcriptTotalCount(){ 
      let total = 0;
      this.transcript.forEach(i => {
        let diff = i.end - i.start
        total = total + diff
      });
      return total
    },
    sentiments(){ return this.JSONText?.sentiments ?? [] },
    total_percentages(){
      // let total = this.transcriptTotalCount
      let all_t = this.transcript
      let speakers = this.speakers
      let before_percentage = []
      for(let i =0;i<speakers.length;i++){
        before_percentage.push({type:speakers[i],arr:[],sum: 0})
        all_t.forEach((item)=>{
          if(speakers[i] == item.channel_type){
            // let sum = ((item.end - item.start)/ total) * 100
            let sum = item.end - item.start
            before_percentage[i].arr.push(sum)
          }
        })
      }
      for(let j =0;j<before_percentage.length;j++){
        before_percentage[j].arr
        for (let ij = 0; ij < before_percentage[j].arr.length; ij++) {
          before_percentage[j].sum += before_percentage[j].arr[ij];
        }
      }
      // console.log("before_percentage",before_percentage)
      return before_percentage
    }
  },
  validations: {
    forms: {
      // ask_question: {
      //   question: {
      //     required: required,
      //   },
      // },
    },
  },
  methods: {
    scrollView(){
      const container = this.$refs.onlyScrollThis;
      const innerContainer = container.getElementsByClassName('highlight')
      if(innerContainer?.[0]) container.scrollTop = innerContainer?.[0]?.offsetTop - 400
    },
    async fetchTranscript(){
      if(this.api.fetch_transcript.send) return;
      try {
        this.api.fetch_transcript.send=true
        const { data } =  await VOIP_API.endpoints.call_activity.fetchTranscript({
          id: this.uniqueID,
          type: this.type,
        })
        this.api.fetch_transcript.status=1
        this.response.transcript=data
      } catch (ex) {
        this.api.fetch_transcript.status=2
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_transcript.send=false
      }
    },
    async makeTranscript(){
      if(this.api.make_transcript.send) return;
      try {
        this.api.make_transcript.send=true
        await VOIP_API.endpoints.call_activity.makeTranscript({
          id: this.uniqueID,
          type: this.type,
        })
        this.fetchTranscript()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.make_transcript.send=false
      }
    },
    async download() {
      if(this.api.download.send) return;
      try {
        this.api.download.send=true
        const response = await fetch(this.soundFile)
        const blob = await response.blob()
        let blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.download.send=false
      }
    },
    // async askQuestion(){
    //   this.$v.forms.ask_question.$touch()
    //   if(this.api.ask_question.send || this.$v.forms.ask_question.$error) return;
    //   try {
    //     this.api.ask_question.status=0
    //     this.api.ask_question.send=true
    //     const { body: stream, headers: { 'x-task-id': task_id } } = await fetch(`${AMI_SOCKET.url}/api/v1/transcription/ask-question`,{
    //       headers: {
    //         'Authorization': AMI_SOCKET.token,
    //       },
    //       method: 'post',
    //       body: JSON.stringify({
    //         "question": this.forms.ask_question.question,
    //         "uniq_id": this.uniqueID,
    //       }),
    //       redirect: "follow"
    //     })
    //     this.api.ask_question.status=1
    //     this.forms.ask_question.reset()
    //     this.$v.forms.ask_question.$reset()
    //     this.response.ask_question.task_id=task_id
    //     this.response.ask_question.text=''
    //     const reader = stream.getReader();
    //     const decoder = new TextDecoder();
    //     let done = false;
    //     while (!done) {
    //       const { value, done: doneReading } = await reader.read();
    //       done = doneReading;
    //       const chunk = decoder.decode(value, { stream: true });
    //       if (chunk) {
    //         const messages = chunk.split('\n\n')
    //         for (let index = 0; index < messages.length; index++) {
    //           const message = messages[index];
    //           const eventChunk = message.slice(0,message.indexOf('data:'))
    //           const dataChunk = message.slice(message.indexOf('data:'),message.length)
    //           const event = eventChunk?.replace?.('event:','')?.replace?.('\n','')
    //           const data = dataChunk?.replace?.('data:','')
    //           if(event=='message') {
    //             this.response.ask_question.text+=`${data.trim()} `
    //           }
    //         }
    //       }
    //     }
    //   } catch(ex) {
    //     this.api.ask_question.status=2
    //     this.api.ask_question.error_message=ex.own_message || ex.message
    //   } finally {
    //     this.api.ask_question.send=false
    //   }
    // },
    // async cancelAskQuestion(){
    //   if(this.api.ask_question.status!=1 || this.api.cancel_ask_question.send || !this.response.ask_question.task_id) return;
    //   try {
    //     this.api.cancel_ask_question.send=true
    //     await fetch(`${AMI_SOCKET.url}/api/v1/transcription/cancel-process`,{
    //       headers: {
    //         'Authorization': AMI_SOCKET.token,
    //       },
    //       method: 'post',
    //       body: JSON.stringify({
    //         "task_id": this.response.ask_question.task_id,
    //       }),
    //     })
    //     this.response.ask_question.task_id=''
    //     this.response.ask_question.text=''
    //   } catch(ex) {
    //     this.api.cancel_ask_question.error_message=ex.own_message || ex.message
    //   } finally {
    //     this.api.cancel_ask_question.send=false
    //   }
    // },
    // async makeSummary(){
    //   if(this.api.make_summary.send) return;
    //   try {
    //     this.api.make_summary.status=0
    //     this.api.make_summary.send=true
    //     const { body: stream, headers: { 'x-task-id': task_id } } = await fetch(`${AMI_SOCKET.url}/api/v1/transcription/summary`,{
    //       headers: {
    //         'Authorization': AMI_SOCKET.token,
    //       },
    //       method: 'post',
    //       body: JSON.stringify({
    //         "uniq_id": this.uniqueID,
    //       }),
    //       redirect: "follow"
    //     })
    //     this.api.make_summary.status=1
    //     this.response.make_summary.task_id=task_id
    //     this.response.make_summary.text=''
    //     const reader = stream.getReader();
    //     const decoder = new TextDecoder();
    //     let done = false;
    //     while (!done) {
    //       const { value, done: doneReading } = await reader.read();
    //       done = doneReading;
    //       const chunk = decoder.decode(value, { stream: true });
    //       if (chunk) {
    //         const messages = chunk.split('\n\n')
    //         for (let index = 0; index < messages.length; index++) {
    //           const message = messages[index];
    //           const eventChunk = message.slice(0,message.indexOf('data:'))
    //           const dataChunk = message.slice(message.indexOf('data:'),message.length)
    //           const event = eventChunk?.replace?.('event:','')?.replace?.('\n','')
    //           const data = dataChunk?.replace?.('data:','')
    //           if(event=='message') {
    //             this.response.make_summary.text+=`${data.trim()} `
    //           }
    //         }
    //       }
    //     }
    //   } catch(ex) {
    //     this.api.make_summary.status=2
    //     this.api.make_summary.error_message=ex.own_message || ex.message
    //   } finally {
    //     this.api.make_summary.send=false
    //   }
    // },
    // async cancelMakeSummary(){
    //   if(this.api.make_summary.status!=1 || this.api.cancel_make_summary.send || !this.response.make_summary.task_id) return;
    //   try {
    //     this.api.cancel_make_summary.send=true
    //     await fetch(`${AMI_SOCKET.url}/api/v1/transcription/cancel-process`,{
    //       headers: {
    //         'Authorization': AMI_SOCKET.token,
    //       },
    //       method: 'post',
    //       body: JSON.stringify({
    //         "task_id": this.response.make_summary.task_id,
    //       }),
    //     })
    //     this.response.make_summary.task_id=''
    //     this.response.make_summary.text=''
    //   } catch(ex) {
    //     this.api.cancel_make_summary.error_message=ex.own_message || ex.message
    //   } finally {
    //     this.api.cancel_make_summary.send=false
    //   }
    // },
    // async makeKeywords(){
    //   if(this.api.make_keywords.send) return;
    //   try {
    //     this.api.make_keywords.status=0
    //     this.api.make_keywords.send=true
    //     const { body: stream, headers: { 'x-task-id': task_id } } = await fetch(`${AMI_SOCKET.url}/api/v1/transcription/keywords`,{
    //       headers: {
    //         'Authorization': AMI_SOCKET.token,
    //       },
    //       method: 'post',
    //       body: JSON.stringify({
    //         "question": this.forms.make_keywords.question,
    //         "uniq_id": this.uniqueID,
    //       }),
    //       redirect: "follow"
    //     })
    //     this.api.make_keywords.status=1
    //     this.response.make_keywords.task_id=task_id
    //     this.response.make_keywords.text=''
    //     const reader = stream.getReader();
    //     const decoder = new TextDecoder();
    //     let done = false;
    //     while (!done) {
    //       const { value, done: doneReading } = await reader.read();
    //       done = doneReading;
    //       const chunk = decoder.decode(value, { stream: true });
    //       if (chunk) {
    //         const messages = chunk.split('\n\n')
    //         for (let index = 0; index < messages.length; index++) {
    //           const message = messages[index];
    //           const eventChunk = message.slice(0,message.indexOf('data:'))
    //           const dataChunk = message.slice(message.indexOf('data:'),message.length)
    //           const event = eventChunk?.replace?.('event:','')?.replace?.('\n','')
    //           const data = dataChunk?.replace?.('data:','')
    //           if(event=='message') {
    //             this.response.make_keywords.text+=`${data.trim()} `
    //           }
    //         }
    //       }
    //     }
    //   } catch(ex) {
    //     this.api.make_keywords.status=2
    //     this.api.make_keywords.error_message=ex.own_message || ex.message
    //   } finally {
    //     this.api.make_keywords.send=false
    //   }
    // },
    // async cancelMakeKeywords(){
    //   if(this.api.make_keywords.status!=1 || this.api.cancel_make_keywords.send || !this.response.make_keywords.task_id) return;
    //   try {
    //     this.api.cancel_make_keywords.send=true
    //     await fetch(`${AMI_SOCKET.url}/api/v1/transcription/cancel-process`,{
    //       headers: {
    //         'Authorization': AMI_SOCKET.token,
    //       },
    //       method: 'post',
    //       body: JSON.stringify({
    //         "task_id": this.response.make_keywords.task_id,
    //       }),
    //     })
    //     this.response.make_keywords.task_id=''
    //     this.response.make_keywords.text=''
    //   } catch(ex) {
    //     this.api.cancel_make_keywords.error_message=ex.own_message || ex.message
    //   } finally {
    //     this.api.cancel_make_keywords.send=false
    //   }
    // },
  },
  mounted(){
    this.fetchTranscript()
  },
};
</script>

<style lang="scss" scoped>
.speaker {
  .highlight {
    .highlight-word {
      background-color: yellow;
      color: black;
    }
    &.SPEAKER_00 .highlight-word{
      background-color: #3699FF;
      color: white;
    }
    &.SPEAKER_01 .highlight-word{
      background-color: #7C3AED;
      color: white;
    }
    &.SPEAKER_02 .highlight-word{
      background-color: #0EC173;
      color: white;
    }
  }
}
</style>