var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 RecordingListSelect min-width-50percent-modal",
    attrs: {
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _c('span', {
    staticClass: "dialer-settings-title newer mb-0 md-mar-left"
  }, [_vm._v("Sound library")])])]), _c('div', {
    staticClass: "dialer-edit-actions d-flex"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.isEmpty(_vm.selected.files)
    },
    on: {
      "click": function ($event) {
        return _vm.onDone();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Discover a variety of sounds or upload your own files. Use them for services like music on hold, IVR announcements, or voicemail greetings.")])]), _vm.api.fetch_files.send ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "180px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign w-50",
      staticStyle: {
        "height": "64px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "24px"
      }
    })]);
  }), 0)])] : _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newButton mr-2",
    attrs: {
      "disabled": !_vm.getUserPermissions.add_sound_file,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.add_sound_file ? _vm.appNotify({
          message: 'Your package does not support this feature',
          type: 'danger'
        }) : _vm.$modal.show(`${_vm._uid}-AddRecording`, {
          group: _vm.data.group,
          select_category: _vm.data.select_category
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add")])], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD mr-16px"
  }, [_c('b-dropdown', {
    staticClass: "dd-text-dark dd-with-checkbox",
    attrs: {
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-svg', {
          staticClass: "filterDD-icon",
          attrs: {
            "name": "filter-icon",
            "width": "19",
            "height": "18",
            "viewBox": "0 0 19 18",
            "stroke-width": "0",
            "stroke": "",
            "fill": "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.types, function (type) {
    return _c('b-dropdown-item', {
      key: type.value,
      on: {
        "click": function ($event) {
          _vm.filter.files.type = type.value;
        }
      }
    }, [_c('div', {
      staticClass: "w-100 d-flex align-items-center"
    }, [_c('div', {
      class: `actLikeSquareRadio checkboxVersion mr-16px ${_vm.filter.files.type == type.value ? 'checked' : ''}`
    }), _vm._v(" " + _vm._s(type.text) + " ")])]);
  }), 1)], 1)]), _c('div', {
    staticClass: "dialer-tab-content"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "w-100 md-mar-bottom whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search sound file"
    },
    model: {
      value: _vm.filter.files.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.files, "search", $$v);
      },
      expression: "filter.files.search"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm._l(_vm.files, function (recording) {
    return _c('div', {
      key: recording.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between w-100"
    }, [_c('div', {
      staticClass: "RecordingListSelect-item-leftPart w-50 d-flex align-items-center flex-wrap"
    }, [_c('span', {
      staticClass: "dialer-assignee-name font-weight-700 mr-4 minWidth25minus16px"
    }, [_vm._v(_vm._s(recording.label))]), _c('span', {
      staticClass: "newBlueColorTagLike ml-0 fitContent"
    }, [_vm._v(_vm._s(recording.group))])]), _c('div', {
      staticClass: "RecordingListSelect-item-rightPart d-flex align-items-center w-50 justify-content-between"
    }, [_c('vb-audio-player', {
      staticClass: "whiteBGaudioWithGreyRoundedBorder-again calc-100-32px-imp",
      attrs: {
        "src": recording.sound_file
      }
    }), _vm.is_single_select ? [_c('b-icon', {
      attrs: {
        "icon": _vm.selected.files[_vm.localConditions.value_field] == recording[_vm.localConditions.value_field] ? 'check-square-fill' : 'square',
        "variant": "black",
        "font-scale": "1.5"
      },
      on: {
        "click": function ($event) {
          _vm.selected.files = Object.assign({}, recording, {
            type: recording.filter_type
          });
        }
      }
    })] : [_c('b-icon', {
      attrs: {
        "icon": _vm.selected.files[recording[_vm.localConditions.value_field]] ? 'check-circle-fill' : 'circle',
        "variant": "black",
        "font-scale": "1.5"
      },
      on: {
        "click": function ($event) {
          _vm.selected.files[recording[_vm.localConditions.value_field]] ? _vm.$delete(_vm.selected.files, recording[_vm.localConditions.value_field]) : _vm.$set(_vm.selected.files, recording[_vm.localConditions.value_field], Object.assign({}, recording, {
            type: recording.filter_type
          }));
        }
      }
    })]], 2)])]);
  }), _vm.files.length == 0 && !_vm.api.fetch_files.send ? _c('div', {
    staticClass: "text-center mt-20px mb-20px"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("There are no sounds, you can add one")])]) : _vm._e()], 2)])])])], 2), _c('AddRecordingModal', {
    attrs: {
      "modalName": `${_vm._uid}-AddRecording`
    },
    on: {
      "update-list": function ($event) {
        return _vm.fetchFilesDebounce();
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }