<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer_numberSetting">
      <!--<div class="dialer-flex">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Numbers</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title">
          Numbers
          <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'numberSettings' })" class="cursor_pointer infoKbIcon md-mar-left" />
        </h2>
        <div class="d-flex">
          <button class="newButton mr-0" @click="$modal.show('AddNumbersModal')">
            <vb-icon icon="squared-add-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Add</span>
          </button>
          <!~~ <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
            <b-dropdown right>
              <template #button-content>
                <button class="dialer-button dialer-button-primary">
                  <vb-svg name="dialer-largePlus-icon" width="15.996" height="15.996" viewBox="0 0 15.996 15.996" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <span class="sm-mar-left">Add</span>
                </button>
              </template>
              <b-dropdown-item @click="$modal.show('AddNumbersModal')">
                <vb-svg class="fillBlack" name="dialer-largePlus-icon" width="15.996" height="15.996" viewBox="0 0 15.996 15.996" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span class="sm-mar-left">Purchase number</span>
              </b-dropdown-item>
              <b-dropdown-item @click="$modal.show('AddNumberExistingModal')">
                <vb-svg class="fillBlack" name="dialer-largePlus-icon" width="15.996" height="15.996" viewBox="0 0 15.996 15.996" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span class="ml-2">Add caller ID</span>
              </b-dropdown-item>
              <b-dropdown-item @click="$modal.show('AddPortNumberModal')">
                <vb-svg class="fillBlack" name="dialer-largePlus-icon" width="15.996" height="15.996" viewBox="0 0 15.996 15.996" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span class="ml-2">Number porting</span>
              </b-dropdown-item>
              
              <b-dropdown-item v-if="$store.getters.getCurrentUser.appMode === 'standalone'" @click="$modal.show('AddManualNumberModal')">
                <vb-svg class="fillBlack" name="dialer-largePlus-icon" width="15.996" height="15.996" viewBox="0 0 15.996 15.996" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span class="ml-2">Add number(manual)</span>
              </b-dropdown-item>
            </b-dropdown>
          </div> ~~>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom dialer_numberSetting-2nd-section">
        <span class="dialer-settings-intro newerTextVersion mb-0">Manage all telephone numbers and caller IDs within your organization.</span>
        <div class="whiteBGinputWithGreyRoundedBorder seachBox">
          <b-input type="text" placeholder="Search number, label" v-model="filter.numbers.search" />
        </div>
      </div>-->

      <div class="settingsFirstSection">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Numbers</h2>
          </div>
          <div>
            <button class="newButton mr-0" @click="$modal.show('AddNumbersModal')">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add</span>
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            Numbers
            <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'numberSettings' })" class="cursor_pointer infoKbIcon md-mar-left" />
          </h2>
          <div class="settingsFirstSection-right">
            <div class="d-flex justify-content-end align-items-center mt-0">
              <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox">
                <b-input type="text" placeholder="Search number, label" v-model="filter.numbers.search" />
              </div>
              <button class="newButton mr-0" @click="$modal.show('AddNumbersModal')">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Add</span>
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">Manage all telephone numbers and caller IDs within your organization.</span>
      </div>

      <template v-if="!$store.getters.getIsMobile">
        <vb-table 
          textNoRecord=" " 
          id="numbers-table" 
          :isListEmpty="isEmpty(filterTelephoneNumbers)" 
          :listLength="filterTelephoneNumbers.length" 
          :perPage="5" 
          :loading="api.telephone_numbers.send" 
          class="dialer-numberTable-customWidth latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover"
        >
          <template slot="header">
            <tr>
              <th class="dialer-has-sort one">
                <span>DID number</span>
              </th>
              <th class="dialer-has-sort two">
                <span>Assigned to</span>
              </th>
              <th class="dialer-has-sort dialer-col-right three">
              </th>
              <th class="dialer-has-sort four dialer-col-right">
                <span v-b-popover.hover.top="'Caller ID shared with'">Outgoing calls only</span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <tr 
              class="dialer-row-select" 
              v-for="(number, index) in filterTelephoneNumbers" 
              :key="`${number.id}-${number.object}`" 
              v-show="index >= start && index <= end" 
              :class="{ 'red-bg': number.status=='2' }" 
              @click="number.queue_service ? '' : number.status==2 ? appNotify({ 
                type: 'danger', 
                message: 'Unable to proceed because this number is suspended' 
              }) : $modal.show('EditNumbersModal', { 
                number: number 
              })"
            >
              <td class="dialer-row-title one">
                <span class="display-responsive mr-2">DID number :</span>
                <div class="d-flex justify-content-between dialer-numberWithCountry">
                  <div class="d-flex align-items-center">
                    <span class="country-img-container p-0">
                      <vb-icon v-if="number.object === 'TelephoneNumbers'" icon="numbersSettings-table-tdIcon" width="35px" height="35px" />
                      <vb-icon v-else-if="number.object === 'CallerId'" icon="numbersSettings-table-tdIcon-another" width="35px" height="35px" />
                      <vb-icon v-else icon="numbersSettings-table-tdIcon" width="35px" height="35px" />
                    </span>
                    <span class="ml-2 d-flex flex-column dialer-numberInfo justify-content-center" v-if="number.queue_service">
                      <span class="m-0 didNumber">{{ number.name | number_formater }}</span>
                    </span>
                    <span class="ml-2 d-flex flex-column dialer-numberInfo justify-content-center" v-else-if="number.object === 'TelephoneNumbers'">
                      <span class="m-0 didNumber" v-b-tooltip :title="number.is_trial==1?'Default':''">{{ number.number | number_formater }}</span>
                      <span class="m-0 didNumber" v-if="number.number.startsWith('555')">Acess Code</span>
                      <div class="d-flex align-items-center">
                        <small class="didNumber-text mr-2 w-auto h-fit-content addedLabelInsideEditNumbersModal" v-if="number.label">({{ number.label }})</small>
                        <div v-if="number.status==2" class="suspendedTag">Suspended</div>
                      </div>
                    </span>
                    <span class="d-flex flex-column ml-1 dialer-numberInfo justify-content-center" v-else>
                      <span class="m-0 didNumber">{{ number.number | number_formater }}</span>
                      <span class="m-0 didNumber" v-if="number.number.startsWith('555')">Acess Code</span>
                      <div v-if="number.status==2" class="suspendedTag">Suspended</div>
                    </span>
                  </div>
                  <span class="d-flex align-items-center">
                    <img v-if="number.queue_service" class="country-img" style="border-radius:8px;" width="45px" :src="number.name | number_formater | flag_icon"/>
                    <img v-else class="country-img" style="border-radius:8px;" width="45px" :src="number.number | number_formater | flag_icon"/>
                  </span>
                </div>
              </td>
              <td class="dialer-row-title two">
                <span class="display-responsive mr-2">Assigned to :</span>
                <div v-if="number.object === 'TelephoneNumbers'" class="w-fit-content">
                  <template v-if="number.ringgroupId==number.assigned">
                    <!--<div v-for="assigned in number.ringgroup_members" :key="assigned">
                      <Info 
                        :id="assigned"  
                        :is_blf="false"
                      />
                        <!~~ @click="api.update_extension.send ? '' : $modal.show(`${_uid}-CallForwardingSelectExtensionModal`, { 
                          type: 'setting',
                          account: number.assigned,
                          extra_data: {
                            id: number.object=='CallerId' ? number.id : number.real_id
                          },
                        })" ~~>
                    </div>-->
                    <div v-if="number.ringgroup_members[0]" class="d-flex flex-column" >
                      <div class="d-flex align-items-center">
                        <Info 
                          :id="number.ringgroup_members[0]"  
                          :is_blf="false"
                        />
                        <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" v-if="number.ringgroup_members.length>1" @click.stop="conditions.more=conditions.more==number.id?'':number.id">
                          <div class="px-2 rounded more-numbers-tag">
                            <small>{{conditions.more==number.id ? '-' : '+'}} {{ number.ringgroup_members.length-1 }} more</small>
                          </div>
                        </div>
                      </div>
                      <template v-if="conditions.more==number.id">
                        <template v-for="(assigned, index) in number.ringgroup_members.filter((item,index)=>index>0)" >
                          <div class="mt-2" :key="assigned" v-show="index >= start && index <= end" >
                            <Info 
                              :id="assigned"  
                              :is_blf="false"
                            />
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="number.assigned">
                    <div>
                      <Info 
                        :id="number.assigned"  
                        :is_blf="false"
                      />
                        <!-- @click="api.update_extension.send ? '' : $modal.show(`${_uid}-CallForwardingSelectExtensionModal`, { 
                          type: 'setting',
                          account: number.assigned,
                          extra_data: {
                            id: number.object=='CallerId' ? number.id : number.real_id
                          },
                        })" -->
                    </div>
                  </template>
                </div>
              </td>
              
              <td class=" three dialer-col-right">
                <b-spinner v-if="number.queue_service" variant="primary" type="grow" small label="Spinning" />
                <div v-else-if="number.object != 'CallerId'" class="three dialer-col-right d-flex align-items-center">
                  <span class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-16px showOnly-OnHover-insideTable" > 
                    Configure
                  </span>
                  <button 
                    class="dialer-button dialer-button-delete-alt buttonNothing" 
                    type="button" 
                    @click.stop="number.status!=1 ? '' : number.status!='2' && number.object != 'CallerId' ? $modal.show('NumberGraphModal', { 
                      accountcode: number.real_id 
                    }) : ''"
                  >
                    <vb-icon icon="graph-icon" class="graphIcon" height="40.501" width="41.374" />
                  </button>
                </div>
              </td>
              <td class=" four dialer-col-right">
                <span class="display-responsive mr-2">Caller ID shared with :</span>
                <div v-if="!number.queue_service" class="d-flex justify-content-end">
                  <a 
                    class="dialer-link-more text-decoration-underline" 
                    @click.stop="number.status=='2' ? appNotify({ 
                      type: 'danger', 
                      message: 'Unable to proceed because this number is suspended' 
                    }) : $modal.show('UserCallerIdShared', { 
                      extensions: number.extensions, 
                      number: number, 
                      type: 'UserCallerIdShared', 
                    })"
                  >
                    <span class="noOfUsers">{{ number | get_property("extensions.length", 0) }}</span>
                    <span class="ml-1">Users</span>
                  </a>
                </div>
              </td>
            </tr>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="latestShimmerDesign rounded-circle mr-2" style="height:35px;width:35px;"></div>
                    <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  </div>
                  <div class="latestShimmerDesign mr-2" style="height:20px;width:40px;"></div>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                  <div class="d-flex flex-column justify-content-between">
                    <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                    <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                  </div>
                </div>
              </td>
              <td>
                <div class="latestShimmerDesign" style="height:20px;width:100px;"></div>
              </td>
              <td class="dialer-col-right">
                <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
              </td>
            </tr>
          </template>
        </vb-table>
      </template>
      <EditNumbersModal @latest="afterUpdating(false)" @team-update="fetchTeamsDebounce()" @ivr-update="fetchIvrsDebounce()" @queue-update="fetchCallQueuesDebounce()" />
    </section>
    <template v-if="$store.getters.getIsMobile">
      <vb-table textNoRecord=" " :isListEmpty="isEmpty(filterTelephoneNumbers)" 
        :listLength="filterTelephoneNumbers.length" :perPage="5" :loading="api.telephone_numbers.send" 
        class="latestTableDesign-withBlackBorders-again smallerFontSizes ml-1 mt-0" style="width: calc(100% - 14px);"
      >
        <!--<template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span>Type</span>
            </th>
            <th class="dialer-has-sort">
              <span>DID Number</span>
            </th>
            <th class="dialer-has-sort">
              <span>Assigned To</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr class="dialer-row-select numbers-list-999" v-for="(number, index) in filterTelephoneNumbers" :key="`${number.id}-${number.object}`" v-show="index >= start && index <= end" @click="number.object != 'CallerId' ? $modal.show('EditNumbersModal', { number: number }) : ''">
            <td class="dialer-row-title">
              <span class="display-responsive mr-2">Type :</span>
              <!~~ <vb-icon v-if="number.object === 'TelephoneNumbers'" icon="dialer-telephone-icon" width="26.075" height="26.855" />
              <vb-icon v-else icon="dialer-callerID-icon" width="27.384" height="32.285" /> ~~>
              <vb-icon v-if="number.object === 'TelephoneNumbers'" icon="numberSettings-callerID-icon-v2" width="35.075" height="35.855" />
              <vb-icon v-else icon="numberSettings-telephone-icon-v2" width="32.556" height="31.166" />
            </td>
            <td class="dialer-row-title its_first">
              <span class="display-responsive mr-2">DID Number :</span>
              <div class="d-flex dialer-numberWithCountry">
                <span >
                  <img class="country-img" width="45px" :src="number.number | number_formater | flag_icon" />
                </span>
                <span class="ml-2 d-flex flex-column dialer-numberInfo" v-if="number.object === 'TelephoneNumbers'">
                  <span class="m-0 didNumber">{{ number.number | number_formater }}</span>
                  <small class="didNumber-text" v-if="number.label">({{ number.label }})</small>
                </span>
                <span class="d-flex flex-column ml-1 dialer-numberInfo" v-else>
                  <span class="m-0 didNumber">{{ number.number | number_formater }}</span>
                </span>
              </div>
            </td>
            <td class="dialer-row-title">
              <span class="display-responsive mr-2">Assigned To :</span>
              <Info 
                v-if="number.extnType" 
                :is_blf="false"
                :id="number |  get_property('assignedToUsersArr.extn')" 
                :name="number |  get_property('assignedToUsersArr.label')" 
                :sub_info="number |  get_property('assignedToUsersArr.extn')" 
              />
            </td>
          </tr>
        </template>-->
        <!-- <template slot="header">
          <tr>
            <th class="dialer-has-sort one">
              <span>DID number</span>
            </th>
            <th class="dialer-has-sort two">
              <span>Assigned to</span>
            </th>
            <th class="dialer-has-sort four dialer-col-right">
              <span v-b-popover.hover.top="'Caller ID Shared with'">Outgoing call users</span>
            </th>
          </tr>
        </template> -->
        <template #body="{ start, end }">
          <!-- <tr 
            class="dialer-row-select w-100" 
            v-for="(number, index) in filterTelephoneNumbers" 
            :key="`${number.id}-${number.object}`" 
            v-show="index >= start && index <= end" 
            :class="{ 'red-bg': number.status=='2' }" 
            @click="number.queue_service ? '' : number.status==2 ? appNotify({ 
              type: 'danger', 
              message: 'Unable to proceed because this number is suspended' 
            }) : $modal.show('EditNumbersModal', { 
              number: number 
            })"
          >
            <td class="dialer-row-title" style="max-width: 100px">
              <span class="ml-2 d-flex flex-column w-fit-content justify-content-center" v-if="number.queue_service">
                <span class="m-0 w-fit-content">{{ number.name | number_formater }}</span>
              </span>
              <span class="ml-2 d-flex flex-column w-fit-content justify-content-center" v-else-if="number.object === 'TelephoneNumbers'">
                <span class="m-0 w-fit-content">{{ number.number | number_formater }}</span>
                <div class="d-flex align-items-center w-fit-content">
                  <small class=" mr-2 w-auto h-fit-content" v-if="number.label">({{ number.label }})</small>
                  <div v-if="number.status==2" class="suspendedTag">Suspended</div>
                </div>
              </span>
              <span class="d-flex flex-column ml-1 w-fit-content justify-content-center" v-else>
                <span class="m-0 w-fit-content">{{ number.number | number_formater }}</span>
                <div v-if="number.status==2" class="suspendedTag">Suspended</div>
              </span>
            </td>
            <td class="dialer-row-title ">
              <template v-if="number.ringgroupId==number.assigned">
                <div v-for="assigned in number.ringgroup_members" :key="assigned">
                  <Info 
                    :id="assigned"  
                    :is_blf="false"
                  />
                </div>
              </template>
              <template v-else-if="number.assigned">
                <div>
                  <Info 
                    :id="number.assigned"  
                    :is_blf="false"
                  />
                </div>
              </template>
            </td>
            <td class="  dialer-col-right">
              <div v-if="!number.queue_service" class="d-flex justify-content-end">
                <a 
                  class="dialer-link-more text-decoration-underline" 
                  @click.stop="number.status=='2' ? appNotify({ 
                    type: 'danger', 
                    message: 'Unable to proceed because this number is suspended' 
                  }) : $modal.show('UserCallerIdShared', { 
                    extensions: number.extensions, 
                    number: number, 
                    type: 'UserCallerIdShared', 
                  })"
                >
                  <span class="noOfUsers">{{ number | get_property("extensions.length", 0) }}</span>
                  <span class="ml-1">Users</span>
                </a>
              </div>
            </td>
          </tr> -->
          <tr 
            class="dialer-row-select" 
            v-for="(number, index) in filterTelephoneNumbers" 
            :key="`${number.id}-${number.object}`" 
            v-show="index >= start && index <= end" 
            :class="{ 'red-bg': number.status=='2' }" 
            @click="number.queue_service ? '' : number.status==2 ? appNotify({ 
              type: 'danger', 
              message: 'Unable to proceed because this number is suspended' 
            }) : $modal.show('EditNumbersModal', { 
              number: number 
            })"
          >
            <td class="dialer-row-title one">
              <div class="d-flex justify-content-between dialer-numberWithCountry">
                <div class="d-flex align-items-center">
                  <span class="country-img-container p-0 mr-6px">
                    <vb-icon v-if="number.object === 'TelephoneNumbers'" icon="numbersSettings-table-tdIcon" width="25px" height="25px" />
                    <vb-icon v-else-if="number.object === 'CallerId'" icon="numbersSettings-table-tdIcon-another" width="25px" height="25px" />
                    <vb-icon v-else icon="numbersSettings-table-tdIcon" width="25px" height="25px" />
                  </span>
                  <span class="" v-if="number.queue_service">
                    <span class="headingInsideTable">{{ number.name | number_formater }}</span>
                  </span>
                  <span class="" v-else-if="number.object === 'TelephoneNumbers'">
                    <span class="headingInsideTable" v-b-tooltip :title="number.is_trial==1?'Default':''">{{ number.number | number_formater }}</span>
                    <div class="d-flex align-items-center">
                      <small class="mr-8px textInsideCard" v-if="number.label">({{ number.label }})</small>
                      <div v-if="number.status==2" class="suspendedTag">Suspended</div>
                    </div>
                  </span>
                  <span class="d-flex flex-column dialer-numberInfo justify-content-center" v-else>
                    <span class="headingInsideTable">{{ number.number | number_formater }}</span>
                    <div v-if="number.status==2" class="suspendedTag">Suspended</div>
                  </span>
                </div>
              </div>
              <div v-if="!number.queue_service" class="mt-8px">
                <a 
                  class="dialer-link-more text-decoration-underline" 
                  @click.stop="number.status=='2' ? appNotify({ 
                    type: 'danger', 
                    message: 'Unable to proceed because this number is suspended' 
                  }) : $modal.show('UserCallerIdShared', { 
                    extensions: number.extensions, 
                    number: number, 
                    type: 'UserCallerIdShared', 
                  })"
                >
                  <span class="noOfUsers">{{ number | get_property("extensions.length", 0) }}</span>
                  <span class="ml-1">Users</span>
                </a>
              </div>
            </td>
            <td class="right dialer-col-right pr-1">
              <div v-if="number.object === 'TelephoneNumbers'" class="d-flex justify-content-end">
                <template v-if="number.ringgroupId==number.assigned">
                  <div v-if="number.ringgroup_members[0]" class="d-flex flex-column align-items-end" >
                    <div class="d-flex align-items-center justify-content-end">
                      <Info 
                        :id="number.ringgroup_members[0]"  
                        :is_blf="false"
                      />
                      <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1" v-if="number.ringgroup_members.length>1" @click.stop="conditions.more=conditions.more==number.id?'':number.id">
                        <div class="pl-1 rounded more-numbers-tag">
                          <small>({{conditions.more==number.id ? '-' : '+'}} {{ number.ringgroup_members.length-1 }})</small>
                        </div>
                      </div>
                    </div>
                    <template v-if="conditions.more==number.id">
                      <template v-for="(assigned, index) in number.ringgroup_members.filter((item,index)=>index>0)" >
                        <div class="mt-2" :key="assigned" v-show="index >= start && index <= end" >
                          <Info 
                            :id="assigned"  
                            :is_blf="false"
                          />
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-else-if="number.assigned">
                  <div>
                    <Info 
                      :id="number.assigned"  
                      :is_blf="false"
                    />
                  </div>
                </template>
              </div>
            </td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="latestShimmerDesign rounded-circle mr-2" style="height:35px;width:35px;"></div>
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                </div>
                <div class="latestShimmerDesign mr-2" style="height:20px;width:40px;"></div>
              </div>
            </td>
            <td>
              <div class="d-flex">
                <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                <div class="d-flex flex-column justify-content-between">
                  <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                </div>
              </div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:100px;"></div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </template>
    <AddNumbersModal @interface="afterUpdating(true)" @manual-number-added="afterUpdating(false)" @port-number-added="afterUpdating(false)" @existing-number-added="afterUpdating(false)" />
    <!-- <AddNumberExistingModal @interface="afterUpdating(false)" /> -->
    <!-- <EditNumbersModal @latest="afterUpdating(false)" /> -->
    <AddRemoveFromList modalName="UserCallerIdShared" @update-list="afterUpdating(false)" />
    <!-- <AddPortNumberModal @added="afterUpdating(true)" />
    <AddManualNumberModal @load="afterUpdating(true)" /> -->
    <AddCallerIdModal />
    <!-- <NumbersGraphModal modalName="NumberGraphModal" @latest="afterUpdating(false)" /> -->
    <GraphModal modalName="NumberGraphModal" />
    <ScheduleAssignModal 
      :disableCurrentUser="false" 
      :modalName="`${_uid}-CallForwardingSelectExtensionModal`" 
      @interface="updateExtension($event)" 
    />
    <!-- :name="data.assigned" 
      :extn="data.assign_to.extn" 
      :extnType="data.extensionType" 
    -->
  </div>
</template>

<script>
import AddNumbersModal from "../Modals/AddNumbersModal.vue";
import EditNumbersModal from "../Modals/EditNumbersModal.vue";
// import AddNumberExistingModal from "../Modals/AddNumberExistingModal.vue";
import AddRemoveFromList from "../Modals/AddRemoveFromList.vue";
// import AddPortNumberModal from "../Modals/AddPortNumberModal.vue";
// import AddManualNumberModal from "../Modals/AddManualNumberModal.vue";
import AddCallerIdModal from "../Modals/AddCallerIdModal.vue";
// import NumbersGraphModal from "../Modals/NumbersGraphModal.vue";
import GraphModal from "../Modals/GraphModal.vue";
import Info from '../Lists/Info.vue';
import { mapGetters } from 'vuex';
import ScheduleAssignModal from '../Modals/ScheduleAssignModal.vue';
import { VOIP_API } from '@/utils';
import _ from 'lodash'
import { number_formater } from '@/filter';


export default {
  name: "NumbersSettings",
  inject:[
    'isEmpty',
    'getNumbers',
    'appNotify',
    'getCallQueue',
    'getIvrs',
    'getVoipTeams',
  ],
  components: {
    // NumbersGraphModal,
    AddNumbersModal,
    EditNumbersModal,
    // AddNumberExistingModal,
    AddCallerIdModal,
    AddRemoveFromList,
    // AddPortNumberModal,
    // AddManualNumberModal,
    Info,
    GraphModal,
    ScheduleAssignModal,
  },
  data() {
    return {
      conditions: {
        more: '',
      },
      filter: {
        numbers:{
          search: '',
        }
      },
      api: {
        telephone_numbers: this.$helperFunction.apiInstance(),
        update_extension: this.$helperFunction.apiInstance(),
      },
    }; 
  },
  filters: {
    filterType(value) {
      if (value === "CallerId") return "Caller ID";
      return "Telephone Number";
    },
  },
  computed: {
    ...mapGetters([
      'getVoipNumbers',
      'addServiceQueue',
    ]),
    filterTelephoneNumbers() {
      const search = this.filter.numbers.search.toLowerCase().trim();
      const numbers = this.getVoipNumbers;
      const filter_numbers = [...numbers,this.addServiceQueue].filter((val) => {
        if(!val) return false
        else if(val.queue_service){
          if(val.type=='number') return `${val.name}`.trim().toLowerCase().includes(search)
          else return false
        }
        // else return `${number_formater(val.number) || ""} ${number_formater(val.did) || ""}`.toLowerCase().trim().replace(/\D/g,'').includes(search) || 
        // `${number_formater(val.number) || ""} ${number_formater(val.did) || ""} ${val.label || ""}`.toLowerCase().trim().includes(search) || 
        // `${number_formater(val.number) || ""}`.toLowerCase().trim().replaceAll(' ','').includes(search)
        else return `${number_formater(val.did || val.number) || ""} ${number_formater(val.did || val.number,'',true) || ""} ${val.label || ""}`.toLowerCase().trim().includes(search) ||
        `${number_formater(val.did || val.number) || ""} ${number_formater(val.did || val.number,'',true) || ""} ${val.label || ""}`.toLowerCase().trim().replaceAll(' ','').includes(search)
      });
      return filter_numbers
    },
  },
  methods: {
    fetchTelephoneNumber() {
      let vm = this
      if(vm.api.telephone_numbers.send) return;
      vm.api.telephone_numbers.send=true
      this.getNumbers()
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.telephone_numbers.send = false
      });
    },
    afterUpdating(refresh) {
      if (refresh) {
        setTimeout(() => {
          this.fetchTelephoneNumber();
        }, 8 * 1000);
      } else {
        this.fetchTelephoneNumber();
      }
    },
    async updateExtension(event) {
      if(this.api.update_extension.send) return;
      try {
        this.api.update_extension.send=true
        await VOIP_API.endpoints.telephone_number.updateExtn(event?.extra_data?.id, {
          extn: event?.account ?? '',
          field: "extn",
        })
        this.appNotify({
          message: event ? "Successfully Added!" : "Successfully UnAssign!",
          type: "success",
        })
        this.afterUpdating()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_extension.send=false
      }
    },
    // queue
    fetchCallQueuesDebounce: _.debounce(function(){
      this.fetchCallQueues()
    }, 2*1000),
    async fetchCallQueues() {
      try {
        await this.getCallQueue()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }
    },
    // ivr
    fetchIvrsDebounce: _.debounce(function(){
      this.fetchIvrs()
    }, 2*1000),
    async fetchIvrs() {
      try {
        await this.getIvrs()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }
    },
    // team
    fetchTeamsDebounce: _.debounce(function(){
      this.fetchTeams()
    }, 2*1000),
    async fetchTeams() {
      try {
        await this.getVoipTeams(true);
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }
    },
  },
  activated(){
    this.fetchTelephoneNumber();
  },
};
</script>


<style lang="scss" scoped>
.dialer-row-title {
  height: auto;
  // min-height: 50px;
}
</style>
