<template>
  <div>
    <modal class="dialer_animation right_side_popup EditMenuModal EditCallQueueModal" width="40%" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
      <section class="ct-user-new dialer-edit">
        
        <div class="dialer-edit-header">
          <div>
            <h2 class="dialer-settings-title newer">Edit call queue ({{forms.update_label.label}})</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" type="button" @click="$modal.hide(modalName)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <!-- Call Queue name and profile picture section -->
        <div class="latestGreyBox-9-9-2023">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-50">
              <label>Call queue name</label>
              <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
                <img :src="data.profile_image" @error="$event.target.src=local_filess.images.user_male" />
                <input class="w-100 bg-white" type="text" v-model="forms.update_label.label" @input="updateLabel" :maxlength="$v.forms.update_label.label.$params.maxLength.max" />
              </div>
              <p v-if="forms.update_label.submitted && $v.forms.update_label.label.$invalid">
                <span class="errorRed" v-if="!$v.forms.update_label.label.required">Name is required</span>
                <span class="errorRed" v-else-if="!$v.forms.update_label.label.minLength">* Name is should be minimum {{$v.forms.update_label.label.$params.minLength.min}} character</span>
                <span class="errorRed" v-else-if="!$v.forms.update_label.label.maxLength">* Name is can be maximum {{$v.forms.update_label.label.$params.maxLength.max}} character</span>
                <span class="errorRed" v-else-if="!$v.forms.update_label.label.valid">* Name can be number and alphabet</span>
              </p>
            </div>
            <div class="profileImg-section-latest-container">
              <div class="profileImg-section-latest" @click="$modal.show('CallQueueProfileImageUploaderModal', { type: 'CALL_QUEUE', accountcode: callQueueAccountcode, current_image: data.profile_image })">
                <img :src="data.profile_image" @error="$event.target.src=local_filess.images.user_male" />
              </div>
            </div>
          </div>
          <!-- Call Queue Numbers -->
          <template v-if="conditions.numbers" >
            <div class="latestGreyBox-heading-main mt-20px">Call queue numbers</div>
            <div class="latestGreyBox-descriptionText">
              Use this number to dial into the queue, or transfer callers to this number to put them into the queue
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <!-- <ul class="list-unstyled dialer-number-list mb-0">
                  <p v-if="!callQueue.userDid || !callQueue.userDid[0]" class="dialer-box-text mb-0">Assign your queue a number</p>
                  <p v-else v-for="did in callQueue.userDid" :key="did.did" class="dialer-box-text mb-2">
                    <span>
                      <img class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                    </span>
                    <span class="sm-mar-left font-weight-bold">{{ did.did | number_formater }}</span>
                  </p>
                </ul> -->
                <template v-if="!callQueue.userDid || !callQueue.userDid[0]">
                  <p class="dialer-box-text highlight mb-0">Assign your queue a number</p>
                </template>
                <div class="d-flex IVR-numbers-container flex-wrap" v-else>
                  <div v-for="did in callQueue.userDid" :key="did.did" class="newIVRLayout-number-item">
                    <img class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                    <span class="newIVRLayout-number-item-text">{{ did.did | number_formater }}</span>
                  </div>
                </div>
                <div class="lg-mar-left">
                  <button type="button" class="newButton sm-mar-left" @click="$modal.show(`${_uid}-AssignNumberModal`, { accountcode: callQueueAccountcode, })">
                    <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Assign</span>
                  </button>
                </div>
              </div>
            </div>
          </template>
        
        
          <!-- Call Queue Members -->
          <div v-if="conditions.members" class="mt-20px">
            <div class="latestGreyBox-heading-main">Setup your queue members</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-descriptionText">{{callQueue | get_property('members.length',0)}} Users</div>
                </div>
                <button class="newButton md-mar-left" type="button" @click="$modal.show('EditCallQueueMembersModal', { call_queue: callQueue, type: 'CallQueueMembers' })">
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Add/remove user</span>
                </button>
              </div>
            </div>
          </div>
          <!-- all Options Sections -->
          <div class="mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <!-- Main Options -->
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="latestGreyBox-heading-main">Main options</div>
                <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-MainOptions`)">
                  <vb-icon icon="squared-configure-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Configure</span>
                </a>
              </div>
              <!-- Agent Options -->
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="latestGreyBox-heading-main">Agent & timing options</div>
                <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-AgentOptionsModal`)">
                  <vb-icon icon="squared-configure-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Configure</span>
                </a>
              </div>
              <!-- Announcement Options -->
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="latestGreyBox-heading-main">Announcement options</div>
                <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-AnnouncementOptionsModal`)">
                  <vb-icon icon="squared-configure-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Configure</span>
                </a>
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
                <CallForwardingSetting @cf-updated="$emit('cf-updated')" :account="data.accountcode" :forwardCallerId="true" />
              </div>
              <!-- max wait time settings  -->
              <!-- <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="latestGreyBox-heading-main">Maximum wait time settings</div>
                <a class="newButton sm-mar-left" 
                  @click="$modal.show(`${_uid}-CallForwardSettingModal`,{ 
                    account, 
                    accountcode: callQueueAccountcode, 
                    setting: '', 
                    is_ringing_show: false, 
                    is_sec_show: false 
                  })"
                >
                  <vb-icon icon="squared-configure-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Configure</span>
                </a>
              </div> -->
            </div>
          </div>
          <!-- delete -->
          <div class="mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="latestGreyBox-heading-main">Delete this call queue?</div>
                <button class="newDeleteButton" @click="remove()">
                  <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
                  <span class="newDeleteButton-textPart d-flex justify-content-center position-relative">
                    <vb-spinner v-if="api.delete.send" />
                    <span v-else>Delete</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Main Options Modal -->
        <modal class="dialer_animation right_side_popup m-w-600 min-width-50percent-modal" width="600px" height="auto" :scrollable="true" :name="`${_uid}-MainOptions`">
          <section class="ct-user-new dialer-edit">
            <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
              <div class="dialer-edit-header d-block">
                <div class="d-flex justify-content-between align-items-center">
                  <h2 class="dialer-settings-title newer">Main options</h2>
                  <div class="dialer-edit-actions">
                    <a class="newDoneButton" @click="$modal.hide(`${_uid}-MainOptions`)">
                      <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                      <span class="newDoneButton-textPart">Done</span>
                    </a>
                  </div>
                </div>
                <p class="vm--modal-text mb-0">Set up your call queue configuration.</p>
              </div>
              <b-form @submit.prevent="updateMenuOptions()">
                <b-alert :show="!!api.main_options.error_message" variant="danger">{{ api.main_options.error_message }}</b-alert>
                <div class="latestGreyBox-9-9-2023 mt-20px">
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Auto fill</div>
                        <div class="latestGreyBox-descriptionText">By enabling call recording it will record the calls between callers and members.</div>
                      </div>
                      <b-form-checkbox class="newerSwitch" switch :disabled="api.main_options.send" v-model="forms.main_options.autofill" @change="updateMenuOptions"></b-form-checkbox>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Select your ring strategy</div>
                      <div class="latestGreyBox-descriptionText">Calls are distributed among the members handling a queue with one of several strategies.</div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <div>
                          <span class="latestGreyBox-descriptionText">
                            <b>
                              {{forms.main_options.strategy}}
                            </b>
                          </span>
                          <p class="latestGreyBox-descriptionText mb-0">
                            <template v-if="forms.main_options.strategy == 'ringall'">
                              Ring all available agents until one answers
                            </template>
                            <template v-if="forms.main_options.strategy == 'leastrecent'">
                              Ring agent which was least recently called by this queue
                            </template>
                            <template v-if="forms.main_options.strategy == 'fewestcalls'">
                              Ring the agent with fewest completed calls from this queue
                            </template>
                            <template v-if="forms.main_options.strategy == 'random'">
                              Ring random agent
                            </template>
                            <template v-if="forms.main_options.strategy == 'rrmemory'">
                              <!-- Round robin with memory, remember where we left off last ring pass -->
                              It will remember where we left the last ring pass(which member get the calls it will remember it and start the call with the next agent in line).
                            </template>
                            <template v-if="forms.main_options.strategy == 'rrordered'">
                              Same as rrmemory but does not remember where we left off
                            </template>
                            <template v-if="forms.main_options.strategy == 'linear'">
                              <!-- Rings agents in the order specified, for dynamic agents in the order they logged in -->
                              It will call agents in sequential order but its start or pick 1st agent randomly
                            </template>
                            <template v-if="forms.main_options.strategy == 'wrandom'">
                              <!-- Random using the member's penalty as a weighting factor, see asterisk documentation for specifics -->
                              It will call agents in sequential order but its start or pick 1st agent randomly
                            </template>
                          </p>
                        </div>
                        <a class="newButton" @click="$modal.show(`${_uid}-SelectRingStratergy`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    
                    
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Select your music on hold</div>
                      <div class="latestGreyBox-descriptionText">
                        Play music on hold for callers while they wait in line
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>{{ response.call_queue | get_property(`playlist[${forms.main_options.musiconhold}]`,'Select music on hold') }}</b>
                        </span>
                        <a class="newButton" :disabled="api.main_options.send" @click="$modal.show(`${_uid}-SelectMusicOnHoldClass`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Join empty</div>
                      <div class="latestGreyBox-descriptionText">
                        Sets whether callers can be placed in a queue with no agents. Based on different options like 
                        yes, no, loose and strict.
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <div>  
                          <span class="latestGreyBox-descriptionText">
                            <b>
                              {{forms.main_options.joinempty ? forms.main_options.joinempty : 'Select join empty'}}
                            </b>
                          </span>
                          <p class="latestGreyBox-descriptionText mb-0">
                            <template v-if="forms.main_options.joinempty == 'yes'">
                              Allow callers to join a queue 
                            </template>
                            <template v-if="forms.main_options.joinempty == 'no'">
                              Do not allow callers to join a queue
                            </template>
                            <template v-if="forms.main_options.joinempty == 'strict'">
                              Do not allow callers to join a queue even if agents state are unavailable
                            </template>
                            <template v-if="forms.main_options.joinempty == 'loose'">
                              Same as no but it will allow callers to join a queue if agents state are either unavailable or paused 
                            </template>
                          </p>
                        </div>
                        <a class="newButton" :disabled="api.main_options.send" @click="$modal.show(`${_uid}-SelectJoinEmpty`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Leave empty</div>
                      <div class="latestGreyBox-descriptionText">Decide what to with callers if agents are not available to take calls or they are logged out.</div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <div>  
                          <span class="latestGreyBox-descriptionText">
                            <b>
                              {{forms.main_options.leavewhenempty ? forms.main_options.leavewhenempty : 'Select leave an empty'}}
                            </b>
                          </span>
                          <p class="latestGreyBox-descriptionText mb-0">
                            <template v-if="forms.main_options.leavewhenempty == 'yes'">
                              Remove callers from a queue if agents are logged out 
                            </template>
                            <template v-if="forms.main_options.leavewhenempty == 'no'">
                              Do not remove caller from a queue even if there are no agents to take calls
                            </template>
                            <template v-if="forms.main_options.leavewhenempty == 'strict'">
                              Remove callers from a queue even if agents are unavailable or paused
                            </template>
                            <template v-if="forms.main_options.leavewhenempty == 'loose'">
                              Same as yes but callers will remain in a queue if agents are unavailable or paused
                            </template>
                          </p>
                        </div>
                        <a class="newButton" :disabled="api.main_options.send" @click="$modal.show(`${_uid}-SelectLeaveEmpty`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Maximum callers</div>
                      <div class="latestGreyBox-descriptionText">
                        Decide the limit of callers to be placed in a queue. 0 will act as unlimited
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.advance_options.maxlen}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.advance_options.send" @click="$modal.show(`${_uid}-SelectMaximumCallers`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>

            </form>
          </section>
        </modal>

        
        <!-- Agent and timing Options Modal -->
        <modal class="dialer_animation right_side_popup m-w-600 min-width-50percent-modal" width="600px" height="auto" :scrollable="true" :name="`${_uid}-AgentOptionsModal`">
          <section class="ct-user-new dialer-edit">
            <form class="dialer-form" @submit.prevent="''">
              <div class="dialer-edit-header d-block">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <h2 class="dialer-settings-title newer mb-0">Agent and timing options </h2>
                  <div class="dialer-edit-actions">
                    <a class="newDoneButton" @click="$modal.hide(`${_uid}-AgentOptionsModal`)">
                      <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                      <span class="newDoneButton-textPart">Done</span>
                    </a>
                  </div>
                </div>
                <p class="vm--modal-text mb-0">Set up your queue and agent timings.</p>
              </div>
            </form>
          </section>
          <section class="ct-user-new dialer-edit">
            <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
              <b-form @submit.prevent="updateAdvanceOptions()">
                <div class="latestGreyBox-9-9-2023 mt-20px">
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Ring timeout</div>
                      <div class="latestGreyBox-descriptionText">
                        Number of seconds agent phone will ring before considering as timeout
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.advance_options.timeout}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.advance_options.send" @click="$modal.show(`${_uid}-SelectRingTimeout`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Queue priority</div>
                      <div class="latestGreyBox-descriptionText">
                        Gives queues a priority to ensure calls waiting in a higher priority queue will deliver its calls first if there are agents common to both queues.
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.advance_options.weight}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.advance_options.send" @click="$modal.show(`${_uid}-SelectQueuePriority`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Members delay</div>
                      <div class="latestGreyBox-descriptionText">
                        Connect with caller after selected number of seconds or add a delay before you connect with caller
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.agent_options.memberdelay}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.agent_options.send" @click="$modal.show(`${_uid}-SelectMembersDelay`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column w-100">
                        <div class="latestGreyBox-heading-main">Retry</div>
                        <div class="latestGreyBox-descriptionText">Number of seconds to wait before sending a call to next available agent</div>
                        <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                          <span class="latestGreyBox-descriptionText">
                            <b>
                              {{forms.main_options.retry}}
                            </b>
                          </span>
                          <a class="newButton" :disabled="api.main_options.send" @click="$modal.show(`${_uid}-SelectRetry`)">
                            <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                            <span class="newButton-textPart">Change</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Wrap-up time</div>
                      <div class="latestGreyBox-descriptionText">How many seconds to wait before sending a call to an available agent , only after a successful call.</div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.main_options.wrapuptime}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.main_options.send" @click="$modal.show(`${_uid}-SelectWrapUpTime`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Ring timeout restart</div>
                        <div class="latestGreyBox-descriptionText">
                          If enabled, restart the ring timeout for an agent to answer a call
                        </div>
                      </div>
                      <b-form-checkbox class="newerSwitch ml-4" switch :disabled="api.advance_options.send" v-model="forms.advance_options.timeoutrestart" @change="updateAdvanceOptions"></b-form-checkbox>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Ring in use</div>
                        <div class="latestGreyBox-descriptionText">
                          If disabled, skip an agent who are on occupied phone
                        </div>
                      </div>
                      <b-form-checkbox class="newerSwitch ml-4" switch :disabled="api.advance_options.send" v-model="forms.advance_options.ringinuse" @change="updateAdvanceOptions"></b-form-checkbox>
                    </div>
                    
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Report hold time</div>
                        <div class="latestGreyBox-descriptionText">
                          If disabled, hold time of a caller will not be announced to a agent
                        </div>
                      </div>
                      <b-form-checkbox class="newerSwitch ml-0" switch :disabled="api.agent_options.send" v-model="forms.agent_options.reportholdtime" @change="updateAgentOptions"></b-form-checkbox>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column aign-items-start">
                      <div class="d-flex justify-content-between align-items-center w-100">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Auto pause</div>
                          <div class="latestGreyBox-descriptionText">
                            If agent missed a pause them right after, this helps save time as it will not ring an agent who is on paused
                          </div>
                        </div>
                        <b-form-checkbox class="newerSwitch ml-4" switch :disabled="api.advance_options.send" v-model="forms.advance_options.autopause" @change="updateAdvanceOptions"></b-form-checkbox>
                      </div>
                      <div v-if="forms.advance_options.autopause" class="whiteBoxWithBorderInsideGreyBox mt-20px">
                        <div class="whiteBoxWithBorderInsideGreyBox-row">
                          <div class="d-flex flex-column">
                            <div class="latestGreyBox-heading-main">Auto pause busy</div>
                            <div class="latestGreyBox-descriptionText">
                              If enabled then agent who is busy will not be auto paused
                            </div>
                          </div>
                          <b-form-checkbox class="newerSwitch ml-4" switch :disabled="api.advance_options.send" v-model="forms.advance_options.autopausebusy" @change="updateAdvanceOptions"></b-form-checkbox>
                        </div>
                        <div class="whiteBoxWithBorderInsideGreyBox-row">
                          <div class="d-flex flex-column">
                            <div class="latestGreyBox-heading-main">Auto pause unavailable</div>
                            <div class="latestGreyBox-descriptionText">
                              If enabled then agent who is unavailable,offline or device timeout then will not be auto paused
                            </div>
                          </div>
                          <b-form-checkbox class="newerSwitch ml-4" switch :disabled="api.advance_options.send" v-model="forms.advance_options.autopauseunavail" @change="updateAdvanceOptions"></b-form-checkbox>
                        </div>
                        <div class="whiteBoxWithBorderInsideGreyBox-row">
                          <div class="d-flex flex-column">
                            <div class="latestGreyBox-heading-main">Auto pause delay {{ forms.advance_options.autopausedelay }}</div>
                            <div class="latestGreyBox-descriptionText">
                              Give agent a time before it considers them as paused , it will only work if agent lets supposed picked a call and then if they dont answer their phone they will be consider as paused
                            </div>
                            <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                              <span class="latestGreyBox-descriptionText">
                                <b>
                                  {{forms.advance_options.autopausedelay}}
                                </b>
                              </span>
                              <a class="newButton" :disabled="api.advance_options.send" @click="$modal.show(`${_uid}-SelectAutoPauseDelay`)">
                                <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                                <span class="newButton-textPart">Change</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Call recording</div>
                        <div class="latestGreyBox-descriptionText">Record incoming calls to agents</div>
                      </div>
                      <b-form-checkbox class="newerSwitch" switch :disabled="api.advance_options.send" v-model="forms.advance_options.monitor_join" @change="updateAdvanceOptions"></b-form-checkbox>
                    </div>
                  </div>
                </div>
              </b-form>
            </form>
          </section>
        </modal>

        <!-- Announcement Options Modal -->
        <modal class="dialer_animation right_side_popup m-w-600 min-width-50percent-modal" width="600px" height="auto" :scrollable="true" :name="`${_uid}-AnnouncementOptionsModal`">
          <section class="ct-user-new dialer-edit">
            <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
              <div class="dialer-edit-header d-block">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <h2 class="dialer-settings-title newer mb-0">Announcement options modal</h2>
                  <div class="dialer-edit-actions">
                    <a class="newDoneButton" @click="$modal.hide(`${_uid}-AnnouncementOptionsModal`)">
                      <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                      <span class="newDoneButton-textPart">Done</span>
                    </a>
                  </div>
                </div>
                <p class="vm--modal-text mb-0">Set up your call queue announcements for user experience</p>
              </div>
              <b-form @submit.prevent="updateAnnouncementOptions()">
                <div class="latestGreyBox-9-9-2023 mt-20px">
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Announce position</div>
                      <div class="latestGreyBox-descriptionText">
                        Based on different option announce position of a caller
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <div>
                          <span class="latestGreyBox-descriptionText">
                            <b>
                              {{forms.advance_options.announce_position ? forms.advance_options.announce_position : 'Select announce position'}}
                            </b>
                          </span>
                          <p class="latestGreyBox-descriptionText mb-0">
                            <template v-if="forms.advance_options.announce_position == 'yes'">
                              It will announce position of a caller in a queue
                            </template>
                            <template v-if="forms.advance_options.announce_position == 'no'">
                              It will skip to announce a position of a caller
                            </template>
                            <template v-if="forms.advance_options.announce_position == 'limit'">
                              Only announce position of a caller to a limited users e.g, if 3 is selected then announce position of caller to first 3 users
                            </template>
                            <template v-if="forms.advance_options.announce_position == 'more'">
                              Announce position of a caller to selected number of callers , once limit is exceeded it will only announce position caller positon but it will announce it as there are already number of callers present in a queue
                            </template>
                          </p>
                        </div>
                        <a class="newButton" :disabled="api.advance_options.send" @click="$modal.show(`${_uid}-SelectAnnouncePosition`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div v-if="['limit','more'].includes(forms.advance_options.announce_position)" class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Announce position limit</div>
                      <div class="latestGreyBox-descriptionText">
                        it will only announce the position of callers to them with respect to the limit, e.g if the limit is 5 then only the first five users will be able to hear their position.
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.advance_options.announce_position_limit}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.advance_options.send" @click="$modal.show(`${_uid}-SelectAnnouncePositionLimit`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Announce hold time</div>
                      <div class="latestGreyBox-descriptionText">
                        Based on different options hold time will be announced to a caller during position announcement
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <div>
                          <span class="latestGreyBox-descriptionText">
                            <b>
                              {{forms.agent_options.announce_holdtime ? forms.agent_options.announce_holdtime : 'Select announce hold time'}}
                            </b>
                          </span>
                          <p class="latestGreyBox-descriptionText mb-0">
                            <template v-if="forms.agent_options.announce_holdtime == 'yes'">
                              Estimated hold time will be announced to a caller
                            </template>
                            <template v-if="forms.agent_options.announce_holdtime == 'no'">
                              Do not announce estimated hold time
                            </template>
                            <template v-if="forms.agent_options.announce_holdtime == 'once'">
                              Announce estimated hold time to a caller only once
                            </template>
                          </p>
                        </div>
                        <a class="newButton" :disabled="api.agent_options.send" @click="$modal.show(`${_uid}-SelectAnnounceHoldTime`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Announce round seconds</div>
                      <div class="latestGreyBox-descriptionText">
                        Controls the value to round to when we announce the caller hold time e.g if hold time is 1 minute
                        and 22 seconds then the value will be rounded to 1 minute and 30 seconds.
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.agent_options.announce_round_seconds ? forms.agent_options.announce_round_seconds : 'Select announce round seconds'}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.agent_options.send" @click="$modal.show(`${_uid}-SelectAnnounceRoundSeconds`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Announce frequency</div>
                      <div class="latestGreyBox-descriptionText">
                        How often to announce the caller position and, or estimated hold time in the queue. Set this value to zero to disable
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.announcement_options.announce_frequency}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.announcement_options.send" @click="$modal.show(`${_uid}-SelectAnnounceFrequency`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Minimum announce frequency</div>
                      <div class="latestGreyBox-descriptionText">
                        Minimum amount of time that must pass before we announce the caller’s position in the queue again. This is used when the caller’s position may change frequently
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.announcement_options.min_announce_frequency}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.announcement_options.send" @click="$modal.show(`${_uid}-SelectMinimumAnnounceFrequency`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="latestGreyBox-heading-main">Periodic frequency</div>
                      <div class="latestGreyBox-descriptionText">
                        How often to make periodic announcements to the caller, 0 to disable
                      </div>
                      <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
                        <span class="latestGreyBox-descriptionText">
                          <b>
                            {{forms.announcement_options.periodic_announce_frequency}}
                          </b>
                        </span>
                        <a class="newButton" :disabled="api.announcement_options.send" @click="$modal.show(`${_uid}-SelectPeriodicFrequency`)">
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">Change</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Announce to first user </div>
                        <div class="latestGreyBox-descriptionText">
                          Announcement will be announced to a caller right after they join a queue
                        </div>
                      </div>
                      <b-form-checkbox class="newerSwitch" switch :disabled="api.advance_options.send" v-model="forms.advance_options.announce_to_first_user" @change="updateAdvanceOptions"></b-form-checkbox>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Relative periodic announcement</div>
                        <div class="latestGreyBox-descriptionText">
                          Announce periodic announcement right after retry or in a relative way
                        </div>
                      </div>
                      <b-form-checkbox class="newerSwitch ml-4" switch :disabled="api.advance_options.send" v-model="forms.advance_options.relative_periodic_announce" @change="updateAdvanceOptions"></b-form-checkbox>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Random periodic announcement</div>
                        <div class="latestGreyBox-descriptionText">
                          Periodic announcement will be announcement in a random order 
                        </div>
                      </div>
                      <b-form-checkbox class="newerSwitch ml-4" switch :disabled="api.advance_options.send" v-model="forms.advance_options.random_periodic_announce" @change="updateAdvanceOptions"></b-form-checkbox>
                    </div>
                  </div>
                
                  <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue report hold</div>
                          <div class="latestGreyBox-descriptionText">Announce caller holdtime to agent before it connects</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_reporthold', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_reporthold.value })" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_reporthold.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_reporthold.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_reporthold.file" />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue thank you</div>
                          <div class="latestGreyBox-descriptionText">Thank you for your patience-periodic announcement</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_thankyou', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_thankyou.value });" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_thankyou.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_thankyou.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_thankyou.file" />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue hold time</div>
                          <div class="latestGreyBox-descriptionText">Announce holdtime to a caller while they wait in line</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_holdtime', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_holdtime.value });" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_holdtime.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_holdtime.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_holdtime.file" />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue calls waiting</div>
                          <div class="latestGreyBox-descriptionText">Notify caller there are already number of callers in a queue waiting</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_callswaiting', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_callswaiting.value });" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_callswaiting.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_callswaiting.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_callswaiting.file" />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue there are</div>
                          <div class="latestGreyBox-descriptionText">Announce position of caller to a caller while they wait in line</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_thereare', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_thereare.value });" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_thereare.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_thereare.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_thereare.file" />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue you are next</div>
                          <div class="latestGreyBox-descriptionText">You are now first in line- Announce position.</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_youarenext', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_youarenext.value });" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_youarenext.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_youarenext.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_youarenext.file" />
                      </div>
                    </div>

                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue caller announce</div>
                          <div class="latestGreyBox-descriptionText">Announce caller position , hold time at start from first user</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_callerannounce', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_callerannounce.value });" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_callerannounce.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_callerannounce.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_callerannounce.file" />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue minute</div>
                          <div class="latestGreyBox-descriptionText">It is for periodic announcement</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_minute', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_minute.value });" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_callerannounce.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_minute.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_minute.file" />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue minutes</div>
                          <div class="latestGreyBox-descriptionText">It is for periodic announcement</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_minutes', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_minutes.value });" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_minutes.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_minutes.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_minutes.file" />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue periodic announce</div>
                          <div class="latestGreyBox-descriptionText">It is for periodic announce</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'periodic_announce', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.periodic_announce.value });" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.periodic_announce.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.periodic_announce.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.periodic_announce.file" />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                      <div class="w-100 d-flex align-items-center justify-content-between">
                        <div class="d-flex flex-column">
                          <div class="latestGreyBox-heading-main">Queue seconds</div>
                          <div class="latestGreyBox-descriptionText">It is for periodic announcement</div>
                        </div>
                        <a class="newButton sm-mar-left" @click="$modal.show(`${_uid}-SelectRecordingSoundFile`, { group: 'announcement', extra_data: { type: 'queue_seconds', }, conditions: { value_field: 'sound' }, selected: forms.announcement_options.queue_seconds.value })" >
                          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                          <span class="newButton-textPart">{{ forms.announcement_options.queue_seconds.value ? 'Change' : 'Add' }}</span>
                        </a>
                      </div>
                      <div v-if="forms.announcement_options.queue_seconds.file" class="w-50 mt-12px">
                        <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`" class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="forms.announcement_options.queue_seconds.file" />
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </form>
          </section>
        </modal>
        
        <!-- Call Queue Members Modal -->
        <modal class="dialer_animation right_side_popup m-w-600 min-width-50percent-modal" width="600px" height="auto" :scrollable="true" :name="`${_uid}-CallQueueMembersModal`">
          <section class="ct-user-new dialer-edit">
            <form class="dialer-form lg-mar-bottom" @submit.prevent="''" >
              <div class="dialer-edit-header">
                <div>
                  <h2 class="dialer-edit-title">Call queue members </h2>
                </div>
                <div class="dialer-edit-actions">
                  <a class="newDoneButton" @click="$modal.hide(`${_uid}-CallQueueMembersModal`)">
                    <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                    <span class="newDoneButton-textPart">Done</span>
                  </a>
                </div>
              </div>
              <div>
                <div class="dialer-assigned">
                  <div class="dialer-box grayBox">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="dialer-box-header mb-0">Call queue members</div>
                      <div class="lg-mar-left">
                        <button class="dialer-button dialer-button-primary md-mar-left" type="button" @click="$modal.show('EditCallQueueMembersModal', { call_queue: callQueue, type: 'CallQueueMembers' })">
                          <span>Add/Remove user</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </modal>
        
        <!-- Call Queue Numbers Modal --><!-- not being used -->
        <modal class="dialer_animation right_side_popup m-w-600 min-width-50percent-modal" width="600px" height="auto" :scrollable="true" :name="`${_uid}-CallQueueNumbersModal`">
          <section class="ct-user-new dialer-edit">
            <form @submit.prevent="''" class="dialer-form lg-mar-bottom" >
              <div class="dialer-edit-header">
                <div>
                  <h2 class="dialer-edit-title">Call queue numbers modal</h2>
                </div>
                <div class="dialer-edit-actions">
                  <a class="newDoneButton" @click="$modal.hide(`${_uid}-CallQueueNumbersModal`)">
                    <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                    <span class="newDoneButton-textPart">Done</span>
                  </a>
                </div>
              </div>
              <div>
                <div class="dialer-assigned">
                  <div class="dialer-box grayBox">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="dialer-box-header mb-0">Numbers</div>
                        <ul class="list-unstyled dialer-number-list mb-0">
                          <p v-if="!callQueue.userDid || !callQueue.userDid[0]" class="dialer-box-text mb-0">No numbers assigned</p>
                          <p v-for="did in callQueue.userDid" :key="did.did" class="dialer-box-text mb-0">
                            <span style="height:45px;width:45px;">
                              <img class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                            </span>
                            <span class="sm-mar-left font-weight-bold">{{ did.did }}</span>
                          </p>
                        </ul>
                      </div>
                      <div class="lg-mar-left">
                        <button type="button" class="dialer-button dialer-button-primary sm-mar-left" @click="$modal.show(`${_uid}-AssignNumberModal`, { accountcode: callQueueAccountcode, })">Assign</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </modal>

        <ProfileImageUploader modalName="CallQueueProfileImageUploaderModal" @image-uploaded="data.profile_image=$event;getCallQueue()" />
        <!-- <EditCallQueueMembersModal @update-list="getCallQueue();" /> -->
        <RecordingListSelectModal :modalName="`${_uid}-SelectRecordingSoundFile`" :is_single_select="true" @selectedRecordings="onSelectFile($event)" />
        <AssignNumberModal :modalName="`${_uid}-AssignNumberModal`" @added="getCallQueue();" />
        <AddRemoveFromList :modalName="'EditCallQueueMembersModal'" @update-list="getCallQueue();" />
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectRingStratergy`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select your ring strategy</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Calls are distributed among the members handling a queue 
                  with one of several strategies.
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectRingStratergy`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in ringStartegyOptions" :key="option.id" >
                  <div @click="forms.main_options.strategy = option.value;updateMenuOptions()" :class="`actLikeSquareRadio mr-20px ${forms.main_options.strategy == option.value ? 'checked' : ''}`"></div>
                  <div class="">
                    <div class="latestGreyBox-heading-main onlyfirstLetterCapital restLowerCase">{{option.text}}</div>
                    <div class="latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase">{{option.desc}}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectMusicOnHoldClass`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select your music on hold class</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Play music on hold for callers while they wait in line
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectMusicOnHoldClass`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div v-if="musicOnHoldClassOptions.length == 0" class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="latestGreyBox-heading-main">There is nothing to show</div>
                </div>
                <template v-else>
                  <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in musicOnHoldClassOptions" :key="option.id" >
                    <div @click="forms.main_options.musiconhold = option.value;updateMenuOptions()" :class="`actLikeSquareRadio mr-20px ${forms.main_options.musiconhold == option.value ? 'checked' : ''}`"></div>
                    <div class="latestGreyBox-heading-main">{{option.text}}</div>
                  </div>
                </template>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectJoinEmpty`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select join empty</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Sets whether callers can be placed in a queue with no agents. Based on different options like 
                  yes, no, loose and strict.
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectJoinEmpty`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div v-if="joinEmptyOptions.length == 0" class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="latestGreyBox-heading-main">There is nothing to show</div>
                </div>
                <template v-else>
                  <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in joinEmptyOptions" :key="option.id" >
                    <div @click="forms.main_options.joinempty = option.value;updateMenuOptions()" :class="`actLikeSquareRadio mr-20px ${forms.main_options.joinempty == option.value ? 'checked' : ''}`"></div>
                    <div>
                      <div class="latestGreyBox-heading-main onlyfirstLetterCapital restLowerCase">{{option.text}}</div>
                      <div class="latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase">{{option.desc}}</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectLeaveEmpty`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select leave empty</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Sets whether callers can be placed in a queue with no agents. Based on different options like 
                  yes, no, loose and strict.
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectLeaveEmpty`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div v-if="leaveAnEmptyOptions.length == 0" class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="latestGreyBox-heading-main">There is nothing to show</div>
                </div>
                <template v-else>
                  <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in leaveAnEmptyOptions" :key="option.id" >
                    <div @click="forms.main_options.leavewhenempty = option.value;updateMenuOptions()" :class="`actLikeSquareRadio mr-20px ${forms.main_options.leavewhenempty == option.value ? 'checked' : ''}`"></div>
                    <div>
                      <div class="latestGreyBox-heading-main onlyfirstLetterCapital restLowerCase">{{option.text}}</div>
                      <div class="latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase">{{option.desc}}</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectMaximumCallers`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select maximum callers</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Decide the limit of callers to be placed in a queue.
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectMaximumCallers`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.advance_options.maxlen = option;updateAdvanceOptions()" :class="`actLikeSquareRadio mr-20px ${forms.advance_options.maxlen == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectAnnouncePosition`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select announce position</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Based on different option announce position of a caller
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectAnnouncePosition`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
              </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in announcePositionOptions" :key="option.id" >
                  <div @click="forms.advance_options.announce_position = option.value;updateAdvanceOptions()" :class="`actLikeSquareRadio mr-20px ${forms.advance_options.announce_position == option.value ? 'checked' : ''}`"></div>
                  <div>
                    <div class="latestGreyBox-heading-main onlyfirstLetterCapital restLowerCase">{{option.text}}</div>
                    <div class="latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase">{{option.desc}}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectAnnouncePositionLimit`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select announce position limit</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  it will only announce the position of callers to them with respect to the limit, e.g if the limit is 5 then only the first five users will be able to hear their position.
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectAnnouncePositionLimit`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.advance_options.announce_position_limit = option;updateAdvanceOptions()" :class="`actLikeSquareRadio mr-20px ${forms.advance_options.announce_position_limit == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectAnnounceHoldTime`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select announce hold time</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Based on different options hold time will be announced to a caller during position announcement
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectAnnounceHoldTime`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in announceHoldTimeOptions" :key="option.id" >
                  <div @click="forms.agent_options.announce_holdtime = option.value;updateAgentOptions()" :class="`actLikeSquareRadio mr-20px ${forms.agent_options.announce_holdtime == option.value ? 'checked' : ''}`"></div>
                  <div>
                    <div class="latestGreyBox-heading-main">{{option.text}}</div>
                    <div class="latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase">{{option.desc}}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectAnnounceRoundSeconds`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select announce round seconds</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Controls the value to round to when we announce the caller hold time e.g if hold time is 1 minute and 22 seconds then the value will be rounded to 1 minute and 30 seconds.
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectAnnounceRoundSeconds`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in announceRoundSecondsOptions" :key="option.id" >
                  <div @click="forms.agent_options.announce_round_seconds = option.value;updateAgentOptions()" :class="`actLikeSquareRadio mr-20px ${forms.agent_options.announce_round_seconds == option.value ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option.text}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectAnnounceFrequency`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select announce frequency</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  How often to announce the caller position and, or estimated hold time in the queue. Set this value to zero to disable
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectAnnounceFrequency`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.announcement_options.announce_frequency = option;updateAnnouncementOptions('announce_frequency')" :class="`actLikeSquareRadio mr-20px ${forms.announcement_options.announce_frequency == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectMinimumAnnounceFrequency`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select minimum announce frequency</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Minimum amount of time that must pass before we announce the caller’s position in the queue again. This is used when the caller’s position may change frequently
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectMinimumAnnounceFrequency`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.announcement_options.min_announce_frequency = option;updateAnnouncementOptions('min_announce_frequency')" :class="`actLikeSquareRadio mr-20px ${forms.announcement_options.min_announce_frequency == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectPeriodicFrequency`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select periodic frequency</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  how often to make periodic announcements to the caller
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectPeriodicFrequency`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.announcement_options.periodic_announce_frequency = option;updateAnnouncementOptions('periodic_announce_frequency')" :class="`actLikeSquareRadio mr-20px ${forms.announcement_options.periodic_announce_frequency == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectRingTimeout`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select ring timeout</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Number of seconds agent phone will ring before considering as timeout
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectRingTimeout`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.advance_options.timeout = option;updateAdvanceOptions()" :class="`actLikeSquareRadio mr-20px ${forms.advance_options.timeout == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectQueuePriority`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select queue priority</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Gives queues a priority to ensure calls waiting in a higher priority queue will deliver its calls first if there are agents common to both queues.
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectQueuePriority`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.advance_options.weight = option;updateAdvanceOptions()" :class="`actLikeSquareRadio mr-20px ${forms.advance_options.weight == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectMembersDelay`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select members delay</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Connect with caller after selected number of seconds or add a delay before you connect with caller
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectMembersDelay`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.agent_options.memberdelay = option;updateAgentOptions()" :class="`actLikeSquareRadio mr-20px ${forms.agent_options.memberdelay == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectRetry`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select retry</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  Number of seconds to wait before sending a call to next available agent
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectRetry`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.main_options.retry = option;updateMenuOptions()" :class="`actLikeSquareRadio mr-20px ${forms.main_options.retry == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectWrapUpTime`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select wrap-up time</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  How many seconds to wait before sending a call to an available agent , only after a successful call.
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectWrapUpTime`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.main_options.wrapuptime = option;updateMenuOptions()" :class="`actLikeSquareRadio mr-20px ${forms.main_options.wrapuptime == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
        <modal class="modalForDropdown" width="600px" height="auto" :scrollable="true" :name="`${_uid}-SelectAutoPauseDelay`">
          <section class="ct-user-new dialer-edit">
            <div class="dialer-edit-header align-items-start pt-0">
              <div class="flex-1 pr-3">
                <h2 class="dialer-settings-title newer">Select auto pause delay</h2>
                <div class="dialer-settings-intro newerTextVersion w-100 mb-0">
                  This setting will delay the auto-pause of an agent by a certain number of seconds after taking a call.
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton" @click="$modal.hide(`${_uid}-SelectAutoPauseDelay`)">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="option in numbers" :key="option.id" >
                  <div @click="forms.advance_options.autopausedelay = option;updateAdvanceOptions()" :class="`actLikeSquareRadio mr-20px ${forms.advance_options.autopausedelay == option ? 'checked' : ''}`"></div>
                  <div class="latestGreyBox-heading-main">{{option}}</div>
                </div>
              </div>
            </div>
          </section>
        </modal>
      </section>
    </modal>
    
    <CallForwardSettingModal 
      :modalName="`${_uid}-CallForwardSettingModal`" 
    />
  </div>
</template>

<script>
import { LOGGER, VOIP_API, } from "../../utils";
import ProfileImageUploader from "./ProfileImageUploader.vue";
import RecordingListSelectModal from "./RecordingListSelect.vue";
// import EditCallQueueMembersModal from './EditCallQueueMembersModal.vue'
import AssignNumberModal from "./AssignNumberModal.vue";
import CallForwardSettingModal from "../Modals/CallForwardSettingModal.vue";
import CallForwardingSetting from '../Common/CallForwardingSetting.vue';
import { 
  helpers,
  maxLength,
  minLength,
  required, 
  // requiredIf 
} from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import _ from 'lodash';
import AddRemoveFromList from "./AddRemoveFromList.vue";
let announce_positions_options = [
  {
    text: 'yes',
    value: 'yes',
    desc: 'it will announce position of a caller in a queue',
  },
  {
    text: 'no',
    value: 'no',
    desc: 'it will skip to announce a position of a caller',
  },
  {
    text: 'limit',
    value: 'limit',
    desc: 'only announce position of a caller to a limited users e.g, if 3 is selected then announce position of caller to first 3 users',
  },
  {
    text: 'more',
    value: 'more',
    desc: 'announce position of a caller to selected number of callers , once limit is exceeded it will only announce position caller positon but it will announce it as there are already number of callers present in a queue',
  },
]
let join_empty = [
  {
    text: 'loose',
    value: 'loose',
    desc: 'same as no but it will allow callers to join a queue if agents state are either unavailable or paused ',
  },
  {
    text: 'no',
    value: 'no',
    desc: 'do not allow callers to join a queue',
  },
  {
    text: 'strict',
    value: 'strict',
    desc: 'do not allow callers to join a queue even if agents state are unavailable',
  },
  {
    text: 'yes',
    value: 'yes',
    desc: 'Allow callers to join a queue',
  },
]
let leave_empty = [
  {
    text: 'loose',
    value: 'loose',
    desc: 'Same as yes but callers will remain in a queue if agents are unavailable or paused',
  },
  {
    text: 'no',
    value: 'no',
    desc: 'Do not remove caller from a queue even if there are no agents to take calls',
  },
  {
    text: 'strict',
    value: 'strict',
    desc: 'Remove callers from a queue even if agents are unavailable or paused',
  },
  {
    text: 'yes',
    value: 'yes',
    desc: 'Remove callers from a queue if agents are logged out',
  },
]
let tabs = {
  main_options: {
    id: 1,
    text: "Main Options",
  },
  advance_options: {
    id: 2,
    text: "Advance Options",
  },
  agent_options: {
    id: 3,
    text: "Agent Options",
  },
  announcement_options: {
    id: 4,
    text: "Announcement Options",
  },
  call_queue_members: {
    id: 5,
    text: "Call Queue Members",
  },
  call_queue_numbers: {
    id: 6,
    text: "Call Queue Numbers",
  },
}
let ringstrategy = [
  {
    text: 'ringall',
    value: 'ringall',
    desc: 'Ring all available agents until one answers.',
  },
  {
    text: 'leastrecent',
    value: 'leastrecent',
    desc: 'Ring agent which was least recently called by this queue.',
  },
  {
    text: 'fewestcalls',
    value: 'fewestcalls',
    desc: 'Ring the agent with fewest completed calls from this queue.',
  },
  {
    text: 'random',
    value: 'random',
    desc: 'Ring random agent.',
  },
  {
    text: 'rrmemory',
    value: 'rrmemory',
    // desc: 'Round robin with memory, remember where we left off last/ ring pass.',
    desc: 'It will remember where we left the last ring pass(which member get the calls it will remember it and start the call with the next agent in line).',
  },
  {
    text: 'rrordered',
    value: 'rrordered',
    desc: 'Same as rrmemory but does not remember where we left off.',
  },
  // {
  //   text: 'linear',
  //   value: 'linear',
  //   // desc: "Random using the member's penalty as a weighting factor, see asterisk documentation for specifics.",
  //   desc: "It will call agents in sequential order but its start or pick 1st agent randomly.",
  // },
]
let announce_hold_time = [
  {
    text: 'yes',
    value: 'yes',
    desc: 'estimated hold time will be announced to a caller',
  },
  {
    text: 'no',
    value: 'no',
    desc: 'do not announce estimated hold time',
  },
  {
    text: 'once',
    value: 'once',
    desc: 'announce estimated hold time to a caller only once',
  },
]
export default {
  name: "EditCallQueueModal",
  components: {
    ProfileImageUploader,
    AddRemoveFromList,
    // EditCallQueueMembersModal,
    AssignNumberModal,
    CallForwardSettingModal,
    CallForwardingSetting,
    RecordingListSelectModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'EditCallQueueModal',
    },
    condition: {
      type: Object,
    },
  },
  inject: ['local_filess','getCallQueue','appNotify'],
  data() {
    return {
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            label: '',
          }
        }),
        main_options: this.$helperFunction.formInstance({
          data: {
            retry: '0',
            wrapuptime: '0',
            strategy: '',
            musiconhold: '',
            joinempty: '',
            leavewhenempty: '',
            autofill: false,
          }
        }),
        advance_options: this.$helperFunction.formInstance({
          data: {
            maxlen: '0',
            weight: '0',
            monitor_join: false,
            ringinuse: false,
            timeout: '0',
            timeoutrestart: false,
            announce_to_first_user: false,
            relative_periodic_announce: false,
            random_periodic_announce: false,
            announce_position: '',
            announce_position_limit: '0',
            autopause: false,
            autopausedelay: '0',
            autopausebusy: false,
          }
        }),
        agent_options: this.$helperFunction.formInstance({
          data: {
            memberdelay: '0',
            announce_holdtime: '',
            announce_round_seconds: '',
            // timeoutrestart: false,
            reportholdtime: false,
          }
        }),
        announcement_options: this.$helperFunction.formInstance({
          data: {
            periodic_announce_frequency: '0',
            announce_frequency: '0',
            min_announce_frequency: '0',
            queue_reporthold: {
              value: '',
              file: '',
            },
            queue_thankyou: {
              value: '',
              file: '',
            },
            queue_holdtime: {
              value: '',
              file: '',
            },
            queue_callswaiting: {
              value: '',
              file: '',
            },
            queue_thereare: {
              value: '',
              file: '',
            },
            queue_youarenext: {
              value: '',
              file: '',
            },
            queue_callerannounce: {
              value: '',
              file: '',
            },
            queue_minute: {
              value: '',
              file: '',
            },
            queue_minutes: {
              value: '',
              file: '',
            },
            queue_quantity1: {
              value: '',
              file: '',
            },
            queue_quantity2: {
              value: '',
              file: '',
            },
            queue_seconds: {
              value: '',
              file: '',
            },
            periodic_announce: {
              value: '',
              file: '',
            },
          }
        }),
        reset(){
          this.update_label.reset()
          this.main_options.reset()
          this.advance_options.reset();
          this.agent_options.reset();
          this.announcement_options.reset();
        },
      },
      api: {
        detail: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
        advance_options: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, source: true }),
        agent_options: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, source: true }),
        announcement_options: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
        main_options: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, source: true }),
        update_label: this.$helperFunction.apiInstance({ validation_errors: true, }),
        delete: this.$helperFunction.apiInstance({  }),
        reset(){
          this.detail?.reset?.()
          this.advance_options?.reset?.()
          this.agent_options?.reset?.()
          this.announcement_options?.reset?.()
          this.main_options?.reset?.()
          this.update_label?.reset?.()
          this.delete?.reset?.()
        },
      },
      response: this.$helperFunction.stateInstance({
        data: {
          call_queue: {},
        },
      }),
      data: this.$helperFunction.stateInstance({
        data: {
          accountcode: '',
          call_queue: {},
          profile_image: '',
        },
      }),
      selected: this.$helperFunction.stateInstance({
        data: {
          tab: tabs.main_options.id,
        },
      }),
    };
  },
  computed: {
    ...mapGetters([
      'getVoipCallQueues',
      'getCurrentUser'
    ]),
    conditions(){
      return {
        name: this.condition?.name ?? true,
        profile: this.condition?.profile ?? true,
        numbers: this.condition?.numbers ?? true,
        members: this.condition?.members ?? true,
      }
    },
    callQueue(){ return this.getVoipCallQueues?.find?.(call_queue=>call_queue.voipaccount==this.data.accountcode) ?? {} },
    
    tabs(){ return tabs },
    soundFiles(){ return Object.values(this.response.call_queue?.soundFiles ?? {}) ?? [] },
    callQueueAccountcode(){ return this.data?.call_queue?.voipaccount },

    announcePositionOptions(){ return announce_positions_options },

    // announceHoldTimeOptions(){ return Object.keys(this.response.call_queue?.announceholdtime ?? {}).map(i=>{ return { text: this.response.call_queue.announceholdtime[i], value: i } }) },
    announceHoldTimeOptions(){ return announce_hold_time },
    
    announceRoundSecondsOptions(){ return Object.keys(this.response.call_queue?.announceroundsecond ?? {}).map(i=>{ return { text: this.response.call_queue.announceroundsecond[i], value: i } }) },

    periodicFrequencyOptions(){ return Object.keys(this.response.call_queue?.announceroundsecond ?? {}).map(i=>{ return { text: this.response.call_queue.announceroundsecond[i], value: i } }) },
    announceFrequencyOptions(){ return Object.keys(this.response.call_queue?.announceroundsecond ?? {}).map(i=>{ return { text: this.response.call_queue.announceroundsecond[i], value: i } }) },
    minimumAnnounceFrequencyOptions(){ return Object.keys(this.response.call_queue?.announceroundsecond ?? {}).map(i=>{ return { text: this.response.call_queue.announceroundsecond[i], value: i } }) },

    // ringStartegyOptions(){ return Object.keys(this.response.call_queue?.ringstrategy ?? {}).map(i=>{ return { text: this.response.call_queue.ringstrategy[i], value: i } }) },
    ringStartegyOptions(){ return ringstrategy },
    musicOnHoldClassOptions(){ return Object.keys(this.response.call_queue?.playlist ?? {}).map(i=>{ return { text: this.response.call_queue.playlist[i], value: i } }) },
    joinEmptyOptions(){ return join_empty },
    // leaveAnEmptyOptions(){ return Object.keys(this.response.call_queue?.leaveEmpty ?? {}).map(i=>{ return { text: this.response.call_queue.leaveEmpty[i], value: i } }) },
    leaveAnEmptyOptions(){ return leave_empty },
    
    numbers(){
      let array = []
      let count = 0
      for(let i = 0;i < 181;i++){
        i == 0 ? count = 0 : count=count+1
        array.push(count)  
      }
      return array
    }
  },
  validations: {
    forms: {
      update_label: {
        label: {
          required: required,
          minLength: minLength(3),
          maxLength: maxLength(20),
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        },
      },
      main_options: {
        retry: {
          // required
        },
        wrapuptime: {
          // required
        },
        strategy: {
          // required
        },
        musiconhold: {
          // required
        },
        joinempty: {
          // required
        },
        leavewhenempty: {
          // required
        },
        autofill: {
          // required
        },
      },
      advance_options: {
        maxlen: {
          // required
        },
        weight: {
          // required
        },
        // monitor_join: false,
        // ringinuse: false,
        timeout: {
          // required
        },
        // timeoutrestart: false,
        // announce_to_first_user: false,
        // relative_periodic_announce: false,
        // random_periodic_announce: false,
        announce_position: {
          // required
        },
        announce_position_limit: {
          // required: requiredIf(function(){
          //   return ['limit','more'].includes(this.forms.advance_options.announce_position)
          // })
        },
        // autopause: false,
        autopausedelay: {
          // required: requiredIf(function(){
          //   return this.forms.advance_options.autopause
          // })
        },
        // autopausebusy: false,
      },
      agent_options: {
        memberdelay: {
          // required
        },
        announce_holdtime: {
          // required
        },
        announce_round_seconds: {
          // required
        },
        // timeoutrestart: false,
        // reportholdtime: false,
      },
      announcement_options: {
        periodic_announce_frequency: {
          // required
        },
        announce_frequency: {
          // required
        },
        min_announce_frequency: {
          // required
        },
        // queue_reporthold: {
        //   value: '',
        //   file: '',
        // },
        // queue_thankyou: {
        //   value: '',
        //   file: '',
        // },
        // queue_holdtime: {
        //   value: '',
        //   file: '',
        // },
        // queue_callswaiting: {
        //   value: '',
        //   file: '',
        // },
        // queue_thereare: {
        //   value: '',
        //   file: '',
        // },
        // queue_youarenext: {
        //   value: '',
        //   file: '',
        // },
        // queue_callerannounce: {
        //   value: '',
        //   file: '',
        // },
      },
    },
  },
  methods: {
    onSelectFile(event){
      console.log(event)
      const data = this.forms.announcement_options[event?.extra_data?.type]
      if(!data) return;
      const sound_file = event?.data?.sound_file
      const value = event?.data?.sound
      data.value = value
      data.file = sound_file
      this.updateAnnouncementOptions('file_upload',event?.extra_data?.type,event?.data?.sound_file.split('/')[event?.data?.sound_file.split('/').length-1])
    },
    onBeforeOpen(event){
      this.data.call_queue=event?.params?.call_queue ?? {}
      this.data.accountcode=event?.params?.call_queue?.voipaccount ?? ''
      this.data.profile_image=event?.params?.call_queue?.avatar?.image?.image ?? ''
      this.forms.update_label.label=event?.params?.call_queue?.label ?? ''
      this.fetchDetail()
    },
    onBeforeClose(){
      this.data.reset()
      this.api.reset()
      this.response.reset()
      this.forms.reset()
    },
    updateLabel: _.debounce(function(){
      let vm = this
      vm.forms.update_label.submitted=true
      vm.$v.forms.update_label.$touch()
      if(vm.$v.forms.update_label.$invalid) return;
      if(vm.forms.update_label.label==vm.callQueue?.label) return;
      vm.api.update_label.send=true
      vm.api.update_label.validation_errors={}
      VOIP_API.endpoints.menu.labelUpdate(vm.callQueueAccountcode,{
        label: vm.forms.update_label.label
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully updated!",
          type: "success",
        })
        vm.getCallQueue()
      })
      .catch((ex) => {
        vm.api.update_label.validation_errors=ex.own_errors ?? {}
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.forms.update_label.submitted=false
        vm.api.update_label.send=false
      });
    },0.5*1000),
    fetchDetail(){
      let vm = this
      vm.api.detail.send=true
      VOIP_API.endpoints.call_queues.detail(vm.callQueueAccountcode)
      .then(({ data: detail })=>{
        vm.response.call_queue=detail
        //main
        vm.forms.main_options.retry                                     = parseInt(detail?.list?.retry) > '5' ? detail?.list?.retry : '5';
        vm.forms.main_options.wrapuptime                                = detail?.list?.wrapuptime ?? '0';
        vm.forms.main_options.strategy                                  = detail?.list?.strategy ?? '';
        vm.forms.main_options.musiconhold                               = detail?.list?.musiconhold ?? '';
        vm.forms.main_options.joinempty                                 = detail?.list?.joinempty ?? '';
        vm.forms.main_options.leavewhenempty                            = detail?.list?.leavewhenempty ?? '';
        vm.forms.main_options.autofill                                  = detail?.list?.autofill=='yes';
        //advance
        vm.forms.advance_options.maxlen                                 = detail?.list?.maxlen ?? '0';
        vm.forms.advance_options.weight                                 = detail?.list?.weight ?? '0';
        vm.forms.advance_options.monitor_join                           = detail?.list?.monitor_join=='1' ?? false;
        vm.forms.advance_options.ringinuse                              = detail?.list?.ringinuse=='yes' ?? false;
        vm.forms.advance_options.timeout                                = detail?.list?.timeout ?? '0';
        vm.forms.advance_options.timeoutrestart                         = detail?.list?.timeoutrestart=='yes' ?? false;
        vm.forms.advance_options.announce_to_first_user                 = detail?.list?.announce_to_first_user=='yes' ?? false;
        vm.forms.advance_options.relative_periodic_announce             = detail?.list?.relative_periodic_announce=='yes' ?? false;
        vm.forms.advance_options.random_periodic_announce               = detail?.list?.random_periodic_announce=='yes' ?? false;
        vm.forms.advance_options.announce_position                      = detail?.list?.announce_position ?? '';
        vm.forms.advance_options.announce_position_limit                = detail?.list?.announce_position_limit ?? '0';
        vm.forms.advance_options.autopause                              = detail?.list?.autopause=='yes' ?? false;
        vm.forms.advance_options.autopausedelay                         = detail?.list?.autopausedelay ?? '0';
        vm.forms.advance_options.autopausebusy                          = detail?.list?.autopausebusy=='yes' ?? false;
        //agent
        vm.forms.agent_options.memberdelay                              = detail?.list?.memberdelay ?? '0';
        vm.forms.agent_options.announce_holdtime                        = detail?.list?.announce_holdtime ?? '';
        vm.forms.agent_options.announce_round_seconds                   = detail?.list?.announce_round_seconds ?? '';
        // vm.forms.agent_options.timeoutrestart                           = detail?.list?.timeoutrestart=='yes' ?? false;
        vm.forms.agent_options.reportholdtime                           = detail?.list?.reportholdtime=='yes' ?? false;
        //announcement
        vm.forms.announcement_options.periodic_announce_frequency       = detail?.list?.periodic_announce_frequency ?? '0';
        vm.forms.announcement_options.announce_frequency                = detail?.list?.announce_frequency ?? '0';
        vm.forms.announcement_options.min_announce_frequency            = detail?.list?.min_announce_frequency ?? '0';
        vm.forms.announcement_options.queue_reporthold.value            = detail?.list?.queue_reporthold ?? '';
        vm.forms.announcement_options.queue_reporthold.file             = detail?.list?.queue_reporthold_file ?? '';
        vm.forms.announcement_options.queue_thankyou.value              = detail?.list?.queue_thankyou ?? '';
        vm.forms.announcement_options.queue_thankyou.file               = detail?.list?.queue_thankyou_file ?? '';
        vm.forms.announcement_options.queue_holdtime.value              = detail?.list?.queue_holdtime ?? '';
        vm.forms.announcement_options.queue_holdtime.file               = detail?.list?.queue_holdtime_file ?? '';
        vm.forms.announcement_options.queue_callswaiting.value          = detail?.list?.queue_callswaiting ?? '';
        vm.forms.announcement_options.queue_callswaiting.file           = detail?.list?.queue_callswaiting_file ?? '';
        vm.forms.announcement_options.queue_thereare.value              = detail?.list?.queue_thereare ?? '';
        vm.forms.announcement_options.queue_thereare.file               = detail?.list?.queue_thereare_file ?? '';
        vm.forms.announcement_options.queue_youarenext.value            = detail?.list?.queue_youarenext ?? '';
        vm.forms.announcement_options.queue_youarenext.file             = detail?.list?.queue_youarenext_file ?? '';
        vm.forms.announcement_options.queue_callerannounce.value        = detail?.list?.queue_callerannounce ?? '';
        vm.forms.announcement_options.queue_callerannounce.file         = detail?.list?.queue_callerannounce_file ?? '';
        vm.forms.announcement_options.queue_minute.value                = detail?.list?.queue_minute ?? '';
        vm.forms.announcement_options.queue_minute.file                 = detail?.list?.queue_minute_file ?? '';
        vm.forms.announcement_options.queue_minutes.value               = detail?.list?.queue_minutes ?? '';
        vm.forms.announcement_options.queue_minutes.file                = detail?.list?.queue_minutes_file ?? '';
        vm.forms.announcement_options.queue_quantity1.value             = detail?.list?.queue_quantity1 ?? '';
        vm.forms.announcement_options.queue_quantity1.file              = detail?.list?.queue_quantity1_file ?? '';
        vm.forms.announcement_options.queue_quantity2.value             = detail?.list?.queue_quantity2 ?? '';
        vm.forms.announcement_options.queue_quantity2.file              = detail?.list?.queue_quantity2_file ?? '';
        vm.forms.announcement_options.queue_seconds.value               = detail?.list?.queue_seconds ?? '';
        vm.forms.announcement_options.queue_seconds.file                = detail?.list?.queue_seconds_file ?? '';
        vm.forms.announcement_options.periodic_announce.value           = detail?.list?.periodic_announce ?? '';
        vm.forms.announcement_options.periodic_announce.file            = detail?.list?.periodic_announce_file ?? '';
      }).catch((ex)=>{
        LOGGER.danger({ex})
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.detail.send=false
      })
    },
    updateMenuOptions(){
      let vm = this
      vm.forms.main_options.submitted=true
      vm.api.main_options.source?.cancel?.("Requesting Again")
      vm.api.main_options.source = require("axios").default.CancelToken.source();
      vm.$v.forms.main_options.$touch()
      if(vm.$v.forms.main_options.$invalid) return;
      // if(vm.api.main_options.send) return;
      // vm.api.main_options.send=true
      vm.api.main_options.validation_errors={}
      // vm.api.main_options.error_message=''
      VOIP_API.endpoints.call_queues.updateMainOptions(vm.callQueueAccountcode,{
        joinempty: vm.forms.main_options.joinempty,
        leavewhenempty: vm.forms.main_options.leavewhenempty,
        musiconhold: vm.forms.main_options.musiconhold,
        retry: vm.forms.main_options.retry, 
        strategy: vm.forms.main_options.strategy,
        wrapuptime: vm.forms.main_options.wrapuptime,
        autofill: vm.forms.main_options.autofill?'yes':'no'
      },vm.api.main_options.source.token)
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.main_options.validation_errors=ex.own_errors
        // vm.api.main_options.error_message=ex.own_message
        vm.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.forms.main_options.submitted=false
        // vm.api.main_options.send=false
        vm.api.main_options.source=null
      })
    },
    updateAdvanceOptions(){
      let vm = this
      vm.forms.advance_options.submitted=true
      vm.api.advance_options.source?.cancel?.("Requesting Again")
      vm.api.advance_options.source = require("axios").default.CancelToken.source();
      vm.$v.forms.advance_options.$touch()
      if(vm.$v.forms.advance_options.$invalid) return;
      // if(vm.api.advance_options.send) return;
      // vm.api.advance_options.send=true
      vm.api.advance_options.validation_errors={}
      // vm.api.advance_options.error_message=''
      VOIP_API.endpoints.call_queues.updateAdvanceOptions(vm.callQueueAccountcode,{
        // monitor_format: '',
        retry: vm.forms.main_options.retry,
        maxlen: vm.forms.advance_options.maxlen,
        weight: vm.forms.advance_options.weight,
        monitor_join: vm.forms.advance_options.monitor_join?'1':'0',
        ringinuse: vm.forms.advance_options.ringinuse?'yes':'no',
        timeout: vm.forms.advance_options.timeout,
        timeoutrestart: vm.forms.advance_options.timeoutrestart?'yes':'no',
        announce_to_first_user: vm.forms.advance_options.announce_to_first_user?'yes':'no',
        relative_periodic_announce: vm.forms.advance_options.relative_periodic_announce?'yes':'no',
        random_periodic_announce: vm.forms.advance_options.random_periodic_announce?'yes':'no',
        announce_position: vm.forms.advance_options.announce_position,
        announce_position_limit: vm.forms.advance_options.announce_position_limit,
        autopause: vm.forms.advance_options.autopause?'yes':'no',
        autopausedelay: vm.forms.advance_options.autopausedelay,
        autopausebusy: vm.forms.advance_options.autopausebusy?'yes':'no',
      },vm.api.advance_options.source.token)
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.advance_options.validation_errors=ex.own_errors
        // vm.api.advance_options.error_message=ex.own_message
        vm.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.forms.advance_options.submitted=false
        // vm.api.advance_options.send=false
        vm.api.advance_options.source=null
      })
    },
    updateAgentOptions(){
      let vm = this
      vm.forms.agent_options.submitted=true
      vm.api.agent_options.source?.cancel?.("Requesting Again")
      vm.api.agent_options.source = require("axios").default.CancelToken.source();
      vm.$v.forms.agent_options.$touch()
      if(vm.$v.forms.agent_options.$invalid) return;
      // if(vm.api.agent_options.send) return;
      // vm.api.agent_options.send=true
      vm.api.agent_options.validation_errors={}
      // vm.api.agent_options.error_message=''
      VOIP_API.endpoints.call_queues.updateAgentOptions(vm.callQueueAccountcode,{
        timeoutrestart: vm.forms.advance_options.timeoutrestart?'yes':'no',
        announce_round_seconds: vm.forms.agent_options.announce_round_seconds,
        announce_holdtime: vm.forms.agent_options.announce_holdtime,
        reportholdtime: vm.forms.agent_options.reportholdtime?'yes':'no', 
        memberdelay: vm.forms.agent_options.memberdelay,
      },vm.api.agent_options.source.token)
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.agent_options.validation_errors=ex.own_errors
        // vm.api.agent_options.error_message=ex.own_message
        vm.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.forms.agent_options.submitted=false
        // vm.api.agent_options.send=false
        vm.api.agent_options.source=null
      })
    },
    updateAnnouncementOptions(flag,file_flag,file_mp3){
      let vm = this
      vm.forms.announcement_options.submitted=true
      vm.api.announcement_options.source?.cancel?.("Requesting Again")
      vm.api.announcement_options.source = require("axios").default.CancelToken.source();
      vm.$v.forms.announcement_options.$touch()
      if(vm.$v.forms.announcement_options.$invalid) return;
      vm.api.announcement_options.validation_errors={}
      VOIP_API.endpoints.call_queues.updateAnnounceOptions(vm.callQueueAccountcode,{
        flag: flag,
        file_upload_type: file_flag,
        periodic_announce_frequency: vm.forms.announcement_options.periodic_announce_frequency,
        announce_frequency: vm.forms.announcement_options.announce_frequency,
        min_announce_frequency: vm.forms.announcement_options.min_announce_frequency,
        queue_reporthold: file_flag=='queue_reporthold' ? file_mp3 : vm.forms.announcement_options.queue_reporthold.value,
        queue_thankyou: file_flag=='queue_thankyou' ? file_mp3 : vm.forms.announcement_options.queue_thankyou.value,
        queue_holdtime: file_flag=='queue_holdtime' ? file_mp3 : vm.forms.announcement_options.queue_holdtime.value,
        queue_callswaiting: file_flag=='queue_callswaiting' ? file_mp3 : vm.forms.announcement_options.queue_callswaiting.value,
        queue_thereare: file_flag=='queue_thereare' ? file_mp3 : vm.forms.announcement_options.queue_thereare.value,
        queue_youarenext: file_flag=='queue_youarenext' ? file_mp3 : vm.forms.announcement_options.queue_youarenext.value,
        queue_callerannounce: file_flag=='queue_callerannounce' ? file_mp3 : vm.forms.announcement_options.queue_callerannounce.value,
        queue_minute: file_flag=='queue_minute' ? file_mp3 : vm.forms.announcement_options.queue_minute.value,
        queue_minutes: file_flag=='queue_minutes' ? file_mp3 : vm.forms.announcement_options.queue_minutes.value,
        queue_quantity1: vm.forms.announcement_options.queue_quantity1.value,
        queue_quantity2: vm.forms.announcement_options.queue_quantity2.value,
        queue_seconds: file_flag=='queue_seconds' ? file_mp3 : vm.forms.announcement_options.queue_seconds.value,
        periodic_announce: file_flag=='periodic_announce' ? file_mp3 : vm.forms.announcement_options.periodic_announce.value,
      },vm.api.announcement_options.source.token)
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.announcement_options.validation_errors=ex.own_errors
        vm.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.forms.announcement_options.submitted=false
        // vm.api.announcement_options.send=false
        vm.api.announcement_options.source=null
      })
    },
    remove(){
      let vm = this
      if(vm.api.delete.send) return;
      vm.$appConfirmation({
        title: "Delete call queue?",
        message: `Are you sure you want to delete this call queue.`,
        variant: 'danger',
        alert: {
          variant: 'danger',
          title: 'Warning',
          message: `This action is irreversible, You will lose all the configurations for this call queue.`,
        },
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete.send=true
            VOIP_API.endpoints.billing.unsubscribe({
              id: vm.callQueueAccountcode,
              uid: vm.getCurrentUser?.uid,
              accountcode: vm.callQueueAccountcode,
            }).then(()=>{
              vm.appNotify({
                message: 'Successfully Deleted!',
                type: 'success',
              })
              vm.getCallQueue()
              vm.$modal.hide(vm.modalName)
            }).catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(()=>{
              vm.api.delete.send=false
            })
          }
        },
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.dialer-audio-playback {
  @include border-radius($border-radius);
  overflow: hidden;
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-grow: 1;
  .dialer-audio-playback-inner {
    width: 100%;
    background-color: $white;
    padding: $sidebarTop;
    audio {
      min-width: 220px;
      max-width: 250px;
      max-height: 30px;

      &::-webkit-media-controls-panel {
        background-color: $gray-300;
      }
    }
    .dialer-audio-message {
      width: 100%;
      svg {
        @include border-radius(0px);
        background-color: transparent;
        color: $body-color;
        margin-right: 8px;
      }
      .dialer-audio-progress {
        position: relative;
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 24px);
        .progress {
          background-color: $progressBar_bg-color;
          width: calc(100% - 28px);
          .progress-bar {
            transition: width 0.1s ease;
          }
        }
        small {
          &:first-child {
            width: 39px;
            margin-right: 8px;
          }
          &:last-child {
            margin-left: 8px;
          }
        }
        input {
          &[type="range"] {
            width: calc(100% - 84px);
            background-color: transparent;
            -webkit-appearance: none;
            position: absolute;
            left: 42px;
            &::-webkit-slider-runnable-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-moz-range-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-ms-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-webkit-slider-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              -webkit-appearance: none;
              cursor: pointer;
            }
            &::-moz-range-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
            }
            &::-ms-thumb {
              width: 12px;
              height: 12px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
              margin-top: -5px;
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &:focus {
              &::-ms-fill-lower {
                background: $black;
              }
              &::-ms-fill-upper {
                background: $black;
              }
            }
          }
        }
      }
    }
    .dialer-play-button {
      outline: none;
      border: none;
      background: none;
      padding: 2px 6px 3px;
    }

    .dialer-playback-time {
      margin: 0 5px;
      min-width: 25px;
      color: $secondary;
    }
  }
}
</style>
